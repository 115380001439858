import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { BaseProvider } from '@/utils/providers/base-provider';

class I18nProvider extends BaseProvider<VueI18n> {
  constructor() {
    super('I18n');
  }

  public init(): void {
    Vue.use(VueI18n);

    const messages = this.loadLocaleMessages();

    this.instance = new VueI18n({
      locale: process.env.VUE_APP_I18N_LOCALE || 'en',
      fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
      messages
    });

  }

  private loadLocaleMessages() {
    const locales = (require as any).context('../static/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

    const messages: Record<string, any> = {};
    locales.keys().forEach((key: string) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });

    return messages;
  }
}

const provider: I18nProvider = new I18nProvider();

export default provider;
