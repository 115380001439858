









































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import AvailableCustomersDropdown from '@/components/user/AvailableCustomersDropdown.vue';
import ObjectSearch from '@/components/common/ObjectSearch.vue';
import UserStore from '@/store-modules/userStore/userStore';
import EditUserAssignments from '@/components/userAssignments/EditUserAssignments.vue';
import EventBus from '@/shared/event-bus';
import { ICompany, IRole, IUser } from '@/view-models/user-model';
import { cloneDeep, forEach } from 'lodash';
import { UserFormError } from '@/enums/user-form';
import { userFormValidation } from '@/shared/user-helper';
import SharedMethods from '@/shared/shared-methods';
import { TranslateResult } from 'vue-i18n';
import { isStringEmpty } from '@/utils/string';
import userAssignmentsStore from '@/store-modules/userStore/userAssignmentsStore';
import { alphabeticSorter } from '@/utils/array-utils';

@Component({
  name: 'add-user-modal',
  components: { Dropdown, ObjectSearch, AvailableCustomersDropdown, EditUserAssignments },
})
export default class AddUserModal extends BaseComponent {
  public userStore = UserStore;
  public vModelShow: boolean = false;
  public editUser: boolean = false;
  public isFirstPage: boolean = true;
  public assetKeys: string[] = [];
  public dOrganizationName: string = 'Select Company';

  private internalCompanies: ICompany[] = this.userStore.getInternalCompaniesByPermission;
  private customerCompanies: ICompany[] = this.userStore.getCustomerCompaniesByPermission;
  private assignedCompanies: ICompany[] = this.userStore.getCustomerCompaniesByPermission;

  private searchResultCompanies: ICompany[] | any = null;
  private searchSelectedCompanies: ICompany[] | any = null;
  private systemRoles: IRole[] = this.userStore.getSystemRoles;
  private customRoles: IRole[] = this.userStore.getCustomRoles;
  private searchResultRoles: IRole[] | any = null;

  private userEmailDuplicationError: string | undefined = this.userStore.getUserEmailError;

  private selectedInternalCompany: string | undefined = '';
  private selectedCustomerCompanies: ICompany[] = [];

  private myInternalCompany: ICompany | undefined;

  private companyError: string | TranslateResult = '';

  private finalNewUserCompany: ICompany | undefined = this.userStore.getCompanies[0];
  private editUserError: UserFormError[] = [];
  private userFormError = UserFormError;

  private sharedMethods: SharedMethods = new SharedMethods(this.userStore);

  public roleOrgName(role: IRole): string | undefined {
    return this.sharedMethods.getOrgName(role);
  }

  get organizationName(): string {
    if (!this.userStore.selectedUser) {
      return '';
    }
    const userOrgKey = this.userStore.selectedUser.orgKey;
    const foundCompany = this.userStore.allCompanies.find((company) => company.key === userOrgKey);
    return foundCompany ? foundCompany.name : '';
  }

  public get getAssignableInternalOrgs(): ICompany[] {
    return this.internalCompanies.sort(alphabeticSorter((c) => c.name));
  }

  public get getAssignableExternalOrgs(): ICompany[] {
    return this.customerCompanies.sort(alphabeticSorter((c) => c.name));
  }

  public get firstName(): string {
    return this.userStore.selectedUser?.firstName.replace(/^\s+/g, '') ?? '';
  }

  public set firstName(name: string) {
    this.userStore.selectedUser!.firstName = name;
  }

  public get lastName(): string {
    return this.userStore.selectedUser?.lastName.replace(/^\s+/g, '') ?? '';
  }

  public set lastName(name: string) {
    this.userStore.selectedUser!.lastName = name;
  }

  public get isValidForm(): boolean {
    const hasUserInfo = this.userStore.selectedUser
      ? !isStringEmpty(this.firstName) &&
        !isStringEmpty(this.userStore.selectedUser.orgKey) &&
        !isStringEmpty(this.userStore.selectedUser?.email)
      : false;
    const hasRoles = this.userStore.selectedUser ? (this.userStore.selectedUser.roleKeys ?? []).length > 0 : false;

    return hasUserInfo && hasRoles;
  }

  public get hasAssignments(): boolean {
    return userAssignmentsStore.hasAssignedAssignments;
  }

  get selectedCustomerCompaniesNames(): string | undefined {
    const orgNames = this.selectedCustomerCompanies.map((item) => item.name);
    return orgNames.join(',');
  }

  get filteredRoles() {
    const keys: string[] = [];
    let filteredCustomRoles: IRole[] = [];
    const customerCompanies: ICompany[] = [...this.selectedCustomerCompanies];
    keys.push(this.userStore.selectedUser!.orgKey!);
    customerCompanies.forEach((ele: ICompany) => {
      keys.push(ele.key);
    });
    this.userStore.getCustomRoles.forEach((ele) => {
      const index = keys.findIndex((i) => i === ele.orgKey);
      if (index > -1) {
        filteredCustomRoles.push(ele);
      }
    });
    if (this.resultRoles) {
      filteredCustomRoles = filteredCustomRoles.filter(
        (role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1
      );
    }
    return filteredCustomRoles;
  }

  public resetUser() {
    this.userEmailDuplicationError = '';
    this.editUserError = [];
    this.companyError = '';
    this.userStore.setUserError(undefined);
    this.selectedCustomerCompanies = [];
    this.selectedInternalCompany = '';
    this.myInternalCompany = undefined;
  }

  public get userSkeleton(): IUser {
    return {
      email: '',
      firstName: '',
      lastName: '',
      orgKey: undefined,
      roleKeys: [],
      isCustomerUser: true,
      status: 'Pending',
      userAssignments: [],
    };
  }

  public get roleNames(): string[] {
    const matchingRoleNames: string[] = [];
    if (this.userStore.selectedUser && this.userStore.selectedUser.roleKeys.length > 0) {
      forEach(this.userStore.selectedUser.roleKeys, (roleKey: string) => {
        const roleName: string | undefined = this.userStore.allRoles.find((allRole: IRole) => allRole.key === roleKey)
          ?.name;
        if (roleName) {
          matchingRoleNames.push(roleName);
        }
      });
    }
    return matchingRoleNames;
  }

  public get internalCompanyName(): string | undefined {
    const org = this.userStore.getInternalCompaniesByPermission.find(
      (company: ICompany) => company.key === this.userStore.selectedUser!.orgKey
    );
    this.selectedInternalCompany = org ? org.name : ''; // 1
    return this.selectedInternalCompany;
  }

  public set internalCompanyName(orgName: string | undefined) {
    if (orgName) {
      this.selectedInternalCompany = orgName;
      this.myInternalCompany = this.userStore.getInternalCompaniesByPermission.find(
        (company: ICompany) => company.name === orgName
      );
      this.userStore.selectedUser!.orgKey = this.myInternalCompany?.key;
    } else {
      this.selectedInternalCompany = '';
      this.myInternalCompany = undefined;
      this.userStore.selectedUser!.orgKey = undefined;
    }
  }

  public get customerCompanyNames(): string | undefined {
    let orgNames: string[] = [];
    orgNames = this.selectedCustomerCompanies.map((item) => item.name);
    return orgNames.join(',');
  }

  get resultCompanies(): string[] {
    return [];
  }

  set resultCompanies(values: string[]) {
    this.internalCompanies = this.userStore.getInternalCompaniesByPermission;
    this.customerCompanies = this.userStore.getCustomerCompaniesByPermission;

    if (values) {
      this.internalCompanies = this.internalCompanies.filter(
        (company: ICompany) => values.includes(company.key)
      );
      this.customerCompanies = this.customerCompanies.filter(
        (company: ICompany) => values.includes(company.key)
      );
    }
  }

  get resultSelectedCompanies(): ICompany[] {
    return this.searchSelectedCompanies;
  }

  set resultSelectedCompanies(value: ICompany[]) {
    this.searchSelectedCompanies = value;
    this.assignedCompanies = [...this.selectedCustomerCompanies];
    if (value) {
      this.assignedCompanies = this.assignedCompanies.filter(
        (company: ICompany) => this.searchSelectedCompanies.indexOf(company.key) !== -1
      );
    }
  }

  get resultRoles(): IRole[] {
    return this.searchResultRoles;
  }

  set resultRoles(value: IRole[]) {
    this.searchResultRoles = value;
    this.systemRoles = this.userStore.systemRoles;
    this.customRoles = this.userStore.customRoles;
    if (value) {
      this.systemRoles = this.systemRoles.filter((role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1);
      this.customRoles = this.customRoles.filter((role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1);
    }
  }

  public created(): void {
    EventBus.$on('addUserModal::show', this.setModalData);
  }

  public setModalData(): void {
    this.vModelShow = true;
    this.isFirstPage = true;
    this.userStore.setSelectedUser(cloneDeep(this.userSkeleton));
  }

  public selectOrg(key: string) {
    this.userStore.selectedUser!.orgKey = key;
  }

  public closeModal(): void {
    this.vModelShow = false;
    this.resetUser();
    this.userStore.setCloseEdit(false);
    this.$emit('add-new-user-modal-closed');
  }

  public validateUsersOrg() {
    if (this.userStore.selectedUser!.orgKey == null || this.userStore.selectedUser!.orgKey === '') {
      this.companyError = this.$t(`umHome.error.selectOrg`);
    } else {
      this.companyError = '';
    }
  }

  public userCompExternalAssignments() {
    if (!this.userStore.selectedUser) {
      return;
    }
    if (!this.userStore.selectedUser?.userAssignments) {
      return;
    }
    const distinctOrgKeys = [
      ...new Set(this.userStore.selectedUser.userAssignments?.map((assignment) => assignment.assignedEntityOrgKey)),
    ];

    if (distinctOrgKeys.length > 1) {
      const org = this.userStore.allCompanies.find((company) => company.key === this.userStore.selectedUser!.orgKey);
      if (org?.isCustomer) {
        this.companyError = this.$t(`umHome.error.mustBeInternal`);
        this.editUserError.push(UserFormError.MUSTBEINTERNAL);
        this.isFirstPage = true;
      }
    } else {
      this.companyError = '';
    }
  }

  public togglePage(goBack: boolean = false): void {
    if (goBack) {
      this.isFirstPage = !this.isFirstPage;
      return;
    }
    const validationData = userFormValidation(this.userStore.selectedUser!);
    this.editUserError = validationData.errorType;
    this.userEmailDuplicationError = '';
    this.validateUsersOrg();
    userAssignmentsStore.resetAssignedAssignments();
    if (validationData.valid) {
      if (!this.companyError) {
        this.finalNewUserCompany = this.userStore.getCompanies.find(
          (c: ICompany) => c.key === this.userStore.selectedUser!.orgKey
        );
        this.isFirstPage = !this.isFirstPage;
      }
    }
  }

  public closeOrganizationDropDown(): void {
    if (this.$refs && this.$refs.orgDropdownNew) {
      (this.$refs.orgDropdownNew as Vue & { closeMenu: () => void }).closeMenu();
    }
  }

  public closeRoleDropDown(): void {
    if (this.$refs && this.$refs.roleDropdownNew) {
      (this.$refs.roleDropdownNew as Vue & { closeMenu: () => void }).closeMenu();
    }
  }

  public checkUserAssignedRoles() {
    if (this.userStore.selectedUser) {
      const keys = this.sharedMethods.filterRolesByOrgs(this.userStore.selectedUser);
      if (keys.length === 0) {
        this.userStore.selectedUser.roleKeys = [];
        this.editUserError.push(UserFormError.ROLE);
        this.isFirstPage = true;
      } else {
        this.userStore.selectedUser.roleKeys = keys;
        this.editUserError = [];
      }
    }
  }

  public async createUser() {
    if (this.userStore.selectedUser) {
      this.userStore.selectedUser.userAssignments = userAssignmentsStore.userAssignedAssignments();
      this.checkUserAssignedRoles();
      this.userCompExternalAssignments();
      if (this.editUserError.length === 0) {
        await this.userStore.addUser(this.userStore.selectedUser);
        if (this.userEmailDuplicationError === '' || !this.userEmailDuplicationError) {
          this.userStore.setUserLoaded(false);
          await this.userStore.getAllUsers();
          this.userStore.setUserLoaded(true);
          this.closeModal();
        }
      }
    }
  }

  @Watch('userStore.userEmailDuplicationError', { immediate: true })
  private onNewUserChange(newValue: any, unused: any) {
    this.userEmailDuplicationError = newValue;
    if (newValue && newValue !== '') {
      this.isFirstPage = true;
    }
  }
}
