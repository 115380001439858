import { AxiosInstance } from 'axios';
import { IRole } from '@/view-models/user-model';
import ControllerBaseService from './common/controller-base-service';
import { umHttp } from '@/services/common/http';

export class RolesService extends ControllerBaseService {

  public static async factory(): Promise<RolesService> {
    return new RolesService((await umHttp.instance));​​
  }

  constructor(axiosInstance: AxiosInstance) {
    super('roles', axiosInstance);
  }

  public getAllRoles(): Promise<IRole[]> {
    return this.httpGet<IRole[]>();
  }

  public updateRole(role: IRole | null): Promise<IRole> {
    return this.httpPut<IRole>('', role);
  }

  public addRole(role: IRole): Promise<IRole | string> {
    return this.httpPost<IRole| string>('', role);
  }

  public deleteRole(role: IRole): Promise<void> {
    return this.httpDelete<void>(role.key);
  }
}
