// store
import { IRoleStore } from '@/store-modules/types/role';
import { IUserStore } from '@/store-modules/types/user';
// models
import { ICompany, IRole, IUser, IUserAssignment } from '@/view-models/user-model';

export default class SharedMethods {
    private store: IUserStore | IRoleStore;

    constructor(storeRef: IUserStore | IRoleStore) {
      this.store = storeRef;
    }

    public searchCompanies(searchResultCompanies: string[] | null):
    { internalCompanies: ICompany[], customerCompanies: ICompany[] } {
        let internalCompanies = this.store.getInternalCompaniesByPermission;
        let customerCompanies = this.store.getCustomerCompaniesByPermission;
        if (searchResultCompanies) {
          internalCompanies = internalCompanies.filter(
            (company: ICompany) => searchResultCompanies?.indexOf(company.key) !== -1
          );
          customerCompanies = customerCompanies.filter(
            (company: ICompany) => searchResultCompanies?.indexOf(company.key) !== -1
          );
        }
        return { internalCompanies, customerCompanies };
    }

    public searchCompaniesForFilter(searchResultCompanies: string[] | null):
    { internalCompanies: ICompany[], customerCompanies: ICompany[] } {
        let internalCompanies = this.store.nonAdminInternalCompanies;
        let customerCompanies = this.store.customerCompanies;
        if (searchResultCompanies) {
          internalCompanies = internalCompanies.filter(
            (company: ICompany) => searchResultCompanies?.indexOf(company.key) !== -1
          );
          customerCompanies = customerCompanies.filter(
            (company: ICompany) => searchResultCompanies?.indexOf(company.key) !== -1
          );
        }
        return { internalCompanies, customerCompanies };
    }

    // search roles from users / roles store
    public searchRoles(searchResultRoles: string[] | null):
    { systemRoles: IRole[], customRoles: IRole[] } {
        let systemRoles = this.store.getSystemRoles;
        let customRoles = this.store.getCustomRoles;
        if (searchResultRoles) {
          systemRoles = systemRoles.filter(
            (role: IRole) => searchResultRoles?.indexOf(role.key) !== -1
          );
          customRoles = customRoles.filter(
            (role: IRole) => searchResultRoles?.indexOf(role.key) !== -1
          );
        }
        return { systemRoles, customRoles };
    }

    public getOrgName(entity: IRole | IUser): string | undefined {
      return this.store.getCompanies.
        find((company: ICompany) => company.key === entity.orgKey)?.name;
    }

    public getOrgKey(orgName: string): string {
        const orgKey = this.store.getCompanies.
        find((company: ICompany) => company.name === orgName)?.key;
        return orgKey ? orgKey : '';
    }

    public getAllUserOrgs(user: IUser): ICompany[] {
      let allSelectedOrgs: ICompany[] = [];
      const allSelectedOrgKeys: string[] = [];

      if (user.orgKey) {
        allSelectedOrgKeys.push(user.orgKey);
      }

      if (user.userAssignments && user.userAssignments.length > 0) {
        user.userAssignments.forEach((assign: IUserAssignment) => {
          allSelectedOrgKeys.push(assign.assignedEntityOrgKey);
        });
      }

      if (allSelectedOrgKeys.length > 0) {
        allSelectedOrgs = this.store.getCompanies.filter((org: ICompany) => {
          return allSelectedOrgKeys.indexOf(org.key) !== -1;
        });
      }

      return allSelectedOrgs;
    }

    public filterRolesByOrgs(user: IUser): string[] {
      let filteredRoles: IRole[] = [];
      let filteredRoleKeys: string[] = [];

      // get all orgs related to user
      const allOrgs = this.getAllUserOrgs(user);

      // get all roles related to user
      const userRoles = this.store.getRoles
      .filter((role: IRole) => user.roleKeys.indexOf(role.key) !== -1);

      // filter the roles matching the orgs
      filteredRoles = userRoles.filter((role: IRole ) =>
      allOrgs.some((org: ICompany) => role.orgKey === org.key));
      userRoles.forEach((role: IRole) => {
        if (role.isSystemRole) {
          filteredRoles.push(role);
        }
      });

      // get all roleKeys
      if (filteredRoles.length > 0) {
        filteredRoleKeys = filteredRoles.map((role: IRole) => role.key);
      }

      return filteredRoleKeys;
    }

    // check if date is valid
    public checkDateIsValid(date: string): boolean {
      let valid = true;
      if (date === '0001-01-01T00:00:00' || date === '0001-01-01T00:00:00+00:00' || date === '' || !date) {
        valid = false;
      }
      return valid;
    }

    // get organization by key
    public getOrg(orgKey: string): ICompany | undefined {
      return this.store.getCompanies.
        find((company: ICompany) => company.key === orgKey);
    }

    public filterSystemRoles(user: IUser): string[] {
      let filteredRoles: IRole[] = [];
      let filteredRoleKeys: string[] = [];

      const userRoles = this.store.getRoles
      .filter((role: IRole) => user.roleKeys.indexOf(role.key) !== -1);

      filteredRoles = userRoles.filter((role: IRole ) => role.isSystemRole);

      // get all roleKeys
      if (filteredRoles.length > 0) {
        filteredRoleKeys = filteredRoles.map((role: IRole) => role.key);
      }

      return filteredRoleKeys;
    }
}
