


























































import { Component, Watch } from 'vue-property-decorator';
// other components import
import Dropdown from '@/components/common/Dropdown.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import Loading from '@/components/common/Loading.vue';
import ObjectSearch from '@/components/common/ObjectSearch.vue';
import UserAssignmentsTreeView from '@/components/tree/UserAssignmentsTreeView.vue';
import EditUserAssignments from '@/components/userAssignments/EditUserAssignments.vue';
// store
import UserStore from '@/store-modules/userStore/userStore';
import UserAssignmentsStore from '@/store-modules/userStore/userAssignmentsStore';
import { IAssignmentTreeItemViewModel } from '@/view-models/assignment-model';
import EventBus, { UmEvents } from '@/shared/event-bus';
import { IUserAssignment } from '@/view-models/user-model';

@Component({
  name: 'user-assignments',
  components: {
    Dropdown,
    OnpointModal,
    ObjectSearch,
    Loading,
    UserAssignmentsTreeView,
    EditUserAssignments,
  },
})
export default class UserAssignments extends BaseComponent {
  public userStore = UserStore;
  public userAssignmentsStore = UserAssignmentsStore;
  private userDetailsInEditMode: boolean = false;
  private editUserAssignments: boolean = false;

  private get userAssignments(): IUserAssignment[] | undefined {
    return this.userStore.getSelectedUser?.userAssignments;
  }

  private get userHasRequiredPermission(): boolean {
    return this.userStore.hasCreateUserAndRolePermission;
  }

  private get rootLevelAssignments(): IAssignmentTreeItemViewModel[] {
    return this.userAssignmentsStore.rootLevelAssignments;
  }

  private get containsInaccessibleAssignments(): boolean {
    return this.userAssignmentsStore.containsInaccessibleAssignments;
  }

  private get selectedUserDataReady(): boolean {
    return this.userStore.selectedUser !== null && this.userAssignmentsStore.assignableAssignments.length > 0;
  }

  // Lifecycle handlers
  public mounted(): void {
    EventBus.$on(UmEvents.UserDetailsEditMode, (value: boolean) => (this.userDetailsInEditMode = value));
    EventBus.$on(UmEvents.UserDetailsEditMode, (value: boolean) => (this.userDetailsInEditMode = value));
  }

  public beforeDestroy(): void {
    EventBus.$on(UmEvents.UserDetailsEditMode, (value: boolean) => (this.userDetailsInEditMode = value));
    EventBus.$on(UmEvents.UserDetailsEditMode, (value: boolean) => (this.userDetailsInEditMode = value));
  }

  public switchToEditMode(): void {
    this.editUserAssignments = true;
    EventBus.$emit(UmEvents.UserAssignmentsEditMode, true);
  }

  public closeEditMode(): void {
    this.editUserAssignments = false;
    this.loadUserAssignments();
  }

  // Helper Methods
  public loadUserAssignments(): void {
    this.userAssignmentsStore.setContainsInaccessibleAssignments(false);
    this.userAssignmentsStore.resetAssignedAssignments();
    if (this.userAssignments) {
      for (const userAssignment of this.userAssignments) {
        this.userAssignmentsStore.setAssignedAssignments(userAssignment.assignedEntityKey);
      }
      this.userAssignmentsStore.openAllAssignments();
    }
  }

  @Watch('selectedUserDataReady', { immediate: true })
  @Watch('userStore.selectedUser', { immediate: true })
  private onSelectedUserChanged(ready: boolean) {
    if (ready) {
      this.closeEditMode();
    }
  }
}
