import BootstrapVue from 'bootstrap-vue';
import moment from 'moment';
import Vue from 'vue';

export default () => {
  Vue.use(BootstrapVue);

  Vue.filter('formatDateLong', (value: number) => {
    if (value) {
      return moment(String(value)).format('MMMM D, YYYY [at] h:mm a');
    }
  });
};
