


























import {Vue, Component, Prop, Model} from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'onpoint-modal'
})
export default class OnpointModal extends Vue {

  @Model('change')
  public isOpen?: boolean;
  @Prop({required: true})
  public id?: string;
  @Prop({required: false})
  public title?: string;
  @Prop({required: false})
  public buttonOk?: string;
  @Prop({required: false})
  public buttonCancel?: string;
  @Prop({required: false})
  public buttonBackShow?: boolean;
  @Prop({required: false})
  public buttonBack?: string;
  @Prop({required: false})
  public bigModal?: boolean;
  @Prop({required: false})
  public onOk?: () => {};
  @Prop({required: false})
  public onCancel?: () => {};
  @Prop({required: false})
  public onClose?: () => {};
  @Prop({required: false})
  public onBack?: () => {};

  public get modalSize(): string {
    return this.bigModal ? 'lg' : '';
  }

  public get modalOpen(): boolean {
    return this.isOpen!;
  }
  public set modalOpen(open: boolean) {
    this.$emit('change', open);
  }
}
