import { authGuard } from '@/utils/auth';
import VueRouter, { RouteConfig } from 'vue-router';
import Vue from 'vue';
import { BaseProvider } from '@/utils/providers/base-provider';
import { appRouteBase } from '@/utils/from-parent';
import RoleManagement from '@/components/role/RoleManagement.vue';
import UserManagement from '@/components/user/UserManagement.vue';
import HomePage from '@/components/user/HomePage.vue';
import ErrorPage from '@/components/common/ErrorPage.vue';
import PageNotFound from '@/components/common/PageNotFound.vue';

Vue.use(VueRouter);

export enum PagesEnum {
  userManagement = 'UserManagement',
  home = 'HomePage',
  roleManagement = 'RoleManagement',
  errorPage = 'ErrorPage',
  notFound = 'NotFound',
}

const routes: RouteConfig[] = [
  { path: '/', component: UserManagement, name: PagesEnum.userManagement },
  { path: '/home', component: HomePage, name: PagesEnum.home },
  { path: '/error-403', component: ErrorPage, name: PagesEnum.errorPage },
  { path: '*', component: PageNotFound, name: PagesEnum.notFound }
];

class RouterProvider extends BaseProvider<VueRouter> {
  constructor() {
    super('Router');
    this.instance = new VueRouter({
      mode: 'history',
      base: appRouteBase(),
      routes,
    });
  }

  public init(): void {
    this.instance.beforeEach(authGuard);
  }
}

export default new RouterProvider();
