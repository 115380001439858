

































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
// js functions
import { orderBy, uniq } from 'lodash';
import moment from 'moment';
// other components import
import UserAssignments from '@/components/userAssignments/UserAssignments.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import Loading from '@/components/common/Loading.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import ObjectSearch from '@/components/common/ObjectSearch.vue';
// store
import UserStore from '@/store-modules/userStore/userStore';
import UserAssignmentsStore from '@/store-modules/userStore/userAssignmentsStore';
import { ICompany, IRole, IUser } from '@/view-models/user-model';
import { UserFormError } from '@/enums/user-form';
import { userFormValidation } from '@/shared/user-helper';
import EventBus, { UmEvents, ISubscription, subscription } from '@/shared/event-bus';
import SharedMethods from '@/shared/shared-methods';
import { TranslateResult } from 'vue-i18n';
import { UserStatus } from '@/enums/user-status';
import { BasicPermissions, AdvancedPermissions } from '@/enums/permissions';
import { uniqueBy } from '@/shared/array';

@Component({
  name: 'user-details',
  components: {
    Dropdown,
    OnpointModal,
    ObjectSearch,
    Loading,
    UserAssignments,
  },
})
export default class UserDetails extends BaseComponent {
  public userStore = UserStore;
  public userAssignmentsStore = UserAssignmentsStore;
  private editUser: boolean = false;
  private userAssignmentsInEditMode: boolean = false;
  public showClearAssignmentsModal: boolean = false;

  public modalHeader: string = '';
  public modalButtonText: any = '';
  public activateDeactivateModalIsOpen: boolean = false;

  private internalCompanies: ICompany[] = this.userStore.getInternalCompaniesByPermission;
  private customerCompanies: ICompany[] = this.userStore.getCustomerCompaniesByPermission;

  private searchResultCompanies: ICompany[] | any = null;

  private editUserError: UserFormError[] = [];
  public userFormError = UserFormError;

  private systemRoles: IRole[] = this.userStore.getSystemRoles;
  private customRoles: IRole[] = this.userStore.getCustomRoles;
  private searchResultRoles: IRole[] | any = null;
  private assignmentsEditModeSubs: ISubscription | undefined;

  // new multi org
  private selectedInternalCompany: string | undefined = '';
  // private selectedCustomerCompanies: ICompany[] = [];
  private myInternalCompany: ICompany | undefined;
  private companyError: string | TranslateResult = '';
  private finalNewUserCompany: ICompany | undefined;

  // shared class
  private sharedMethods: SharedMethods = new SharedMethods(this.userStore);

  public get selectedUser(): IUser | null {
    return UserStore.selectedUser;
  }

  public get isUserLoading(): boolean {
    return UserStore.isUserLoading;
  }

  public get getAssignableInternalOrgs(): ICompany[] {
    return UserStore.internalCompanies;
  }

  public get getAssignableExternalOrgs(): ICompany[] {
    return UserStore.customerCompanies;
  }

  public get selectedCustomerCompanies(): ICompany[] {
    const userAssignments = UserStore.selectedUser!.userAssignments;
    const companies: ICompany[] = [];
    UserStore.customerCompanies.forEach((com) => {
      if (userAssignments && userAssignments.some((assignment) => assignment.assignedEntityOrgKey === com.key)) {
        companies.push(com);
      }
    });
    return companies ?? [];
  }

  public roleOrgName(role: IRole): string | undefined {
    return this.sharedMethods.getOrgName(role);
  }

  get showActivateDeactivate(): boolean {
    // If user has system role permission for ManageUserAndRole and DeleteUserAndRole
    // then show the Activate/Deactivate button.
    // If not then check currentUserPermissions in the organization level
    // based on the selected user orgKey.
    if (this.selectedUser && this.userStore.hasSystemRoleCreateUserAndDeleteUserPermission) {
      return true;
    } else if (this.selectedUser) {
      const organization = this.userStore.allCompanies.find((item) => item.key === this.selectedUser?.orgKey);
      if (
        organization &&
        organization.currentUserPermissions?.includes(BasicPermissions.ManageUserAndRole) &&
        organization.currentUserPermissions?.includes(AdvancedPermissions.DeleteUserAndRole)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  get showEditUserIcon(): boolean {
    if (this.userAssignmentsInEditMode) {
      return false;
    }
    // If user has system role permission for ManageUserAndRole and DeleteUserAndRole
    // then show the Activatr/Deactivate button.
    // If not then check currentUserPermissions in the organization level
    // based on the selected user orgKey.
    if (this.selectedUser && this.userStore.hasSystemRoleCreateUserAndRolePermission) {
      return true;
    } else if (this.selectedUser) {
      const organization = this.userStore.allCompanies.find((item) => item.key === this.selectedUser?.orgKey);
      if (
        organization &&
        organization.currentUserPermissions?.includes(BasicPermissions.ManageUserAndRole) &&
        organization.currentUserPermissions?.includes(BasicPermissions.CreateUserAndRole)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  public get showActive() {
    let showActiveStatus = true;
    if (this.selectedUser) {
      showActiveStatus =
        this.selectedUser.status === UserStatus.INACTIVE || this.selectedUser.status === UserStatus.DEACTIVATEPENDING;
    }
    return showActiveStatus;
  }

  public get firstName(): string {
    if (this.selectedUser) {
      return this.selectedUser.firstName.replace(/^\s+/g, '');
    }
    return '';
  }

  public set firstName(name: string) {
    if (this.selectedUser) {
      this.selectedUser.firstName = name;
    }
  }

  public get lastName(): string {
    if (this.selectedUser) {
      return this.selectedUser.lastName.replace(/^\s+/g, '');
    }
    return '';
  }

  public set lastName(name: string) {
    if (this.selectedUser) {
      this.selectedUser.lastName = name;
    }
  }

  public get customFilteredRole(): IRole[] {
    let filteredCustomRoles: IRole[] = [];
    this.userStore.getCustomRoles.forEach((ele) => {
      const index = this.collectOrgKeys.findIndex((i) => i === ele.orgKey);
      if (index > -1) {
        filteredCustomRoles.push(ele);
      }
    });
    if (this.resultRoles) {
      filteredCustomRoles = filteredCustomRoles.filter(
        (role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1
      );
    }
    return filteredCustomRoles;
  }

  private get collectOrgKeys(): string[] {
    let filteredOrgKeys: string[] = uniqueBy(
      this.userStore.selectedUser!.userAssignments ?? [],
      'assignedEntityOrgKey'
    );
    const index = filteredOrgKeys.findIndex((i) => i === this.selectedUser?.orgKey);
    if (index === -1) {
      filteredOrgKeys.push(this.selectedUser?.orgKey!);
    }
    if (this.myInternalCompany) {
      filteredOrgKeys.push(this.myInternalCompany.key);
    }
    if (!this.myInternalCompany && this.selectedCustomerCompanies.length === 1) {
      filteredOrgKeys.push(this.selectedCustomerCompanies[0].key);
    }
    // external org keys filtered only if there are assignments
    if (this.selectedUser?.userAssignments && this.selectedCustomerCompanies) {
      if (this.selectedUser?.userAssignments?.length > 0 && this.selectedCustomerCompanies.length > 0) {
        this.selectedCustomerCompanies.forEach((org: ICompany) => {
          filteredOrgKeys.push(org.key);
        });
      }
    }
    filteredOrgKeys = uniq(filteredOrgKeys);
    return filteredOrgKeys;
  }
  public closeClearAssignmentsModal() {
    this.showClearAssignmentsModal = false;
  }
  public clearAssignments() {
    this.handleUserSave(true);
    this.showClearAssignmentsModal = false;
  }
  public saveUserDetails() {
    const originalUsersOrgKey = this.userStore.allUsers.find((user) => user.key === this.selectedUser!.key)!.orgKey;
    const updatedOrgIsExternal: boolean = this.userStore.allCompanies.find(
      (company) => company.key === this.selectedUser!.orgKey
    )!.isCustomer;
    /* if changing orgs from internal to external or external to external
      (if the new org is external is all that matters for checking purposes)
    show confirm modal, otherwise perform save */
    if (updatedOrgIsExternal && this.selectedUser?.orgKey !== originalUsersOrgKey) {
      this.showClearAssignmentsModal = true;
    } else {
      this.handleUserSave();
    }
  }
  public selectOrg(key: string) {
    this.selectedUser!.orgKey = key;
  }

  // get selected user role names
  public get roleNames(): any {
    // Use set to avoid duplicates, see Tech Debt #37627
    const matchingRoleNames = new Set();
    this.selectedUser?.roleKeys?.forEach((roleKey: string) => {
      const roleName: string | undefined = this.userStore.allRoles.find((allRole: IRole) => allRole.key === roleKey)
        ?.name;
      if (roleName) {
        matchingRoleNames.add(roleName);
      }
    });
    return Array.from(matchingRoleNames);
  }

  get resultRoles(): IRole[] {
    return this.searchResultRoles;
  }

  set resultRoles(value: IRole[]) {
    this.searchResultRoles = value;
    this.systemRoles = this.userStore.systemRoles;
    this.customRoles = this.userStore.customRoles;
    if (value) {
      this.systemRoles = this.systemRoles.filter((role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1);
      this.customRoles = this.customRoles.filter((role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1);
    }
  }

  get resultCompanies(): ICompany[] {
    return this.searchResultCompanies;
  }

  set resultCompanies(value: ICompany[]) {
    this.searchResultCompanies = value;

    this.internalCompanies = this.getAssignableInternalOrgs;
    this.customerCompanies = this.getAssignableExternalOrgs;

    if (value) {
      this.internalCompanies = this.internalCompanies.filter(
        (company: ICompany) => this.searchResultCompanies.indexOf(company.key) !== -1
      );
      this.customerCompanies = this.customerCompanies.filter(
        (company: ICompany) => this.searchResultCompanies.indexOf(company.key) !== -1
      );
    }
  }

  get hasNoOrganizationName(): boolean {
    return this.organizationName === '' || this.organizationName == null;
  }

  get organizationName(): string | undefined {
    if (!this.selectedUser) {
      return;
    }
    const userOrg = this.userStore.allCompanies.find((company) => company.key === this.selectedUser!.orgKey);
    return userOrg?.name ?? '';
  }

  public mounted(): void {
    this.assignmentsEditModeSubs = subscription(
      UmEvents.UserAssignmentsEditMode,
      (value: boolean) => (this.userAssignmentsInEditMode = value)
    );
  }

  public beforeDestroy(): void {
    this.assignmentsEditModeSubs?.unsubscribe();
  }

  public isUserCompanyInternal(): boolean {
    return this.userStore.getInternalCompaniesByPermission.some(
      (comp: ICompany) => comp.key === this.selectedUser?.orgKey
    );
  }
  public get selectedCustomerCompaniesNames(): string | undefined {
    return this.getAssignableInternalOrgs.map((item) => item).join(', ');
  }

  public resetUser() {
    this.selectedInternalCompany = '';
    this.myInternalCompany = undefined;
    this.companyError = '';
    this.finalNewUserCompany = undefined;
  }
  public setModalTexts() {
    this.modalButtonText = this.showActive ? this.$t(`umHome.activate`) : this.$t(`umHome.deactivate`);
    this.modalHeader = this.showActive
      ? this.$t(`umHome.activate`) + ' ' + this.$t(`umHome.user`)
      : this.$t(`umHome.deactivate`) + ' ' + this.$t(`umHome.user`);
  }

  public formatDate(date: string): string {
    return moment(String(date)).format('MM/DD/YYYY h:mm a');
  }

  public formatDateResendMail(date: string): string {
    return moment(String(date)).format('MMMM D, YYYY h:mm a');
  }

  // close organization dropdown
  public closeOrganizationDropDown(): void {
    if (this.$refs && this.$refs.orgDropdown) {
      (this.$refs.orgDropdown as Vue & { closeMenu: () => void }).closeMenu();
    }
  }

  public validateUserDetails() {
    if (this.selectedUser?.orgKey === '') {
      this.companyError = this.$t(`umHome.error.atleastOneOrg`);
    }
  }
  // check roles assigned
  public checkUserAssignedRoles(): { valid: boolean; errorType: UserFormError[] } {
    let formValid = true;

    // if error show error else filter assign new roles to user
    if (this.selectedUser) {
      const keys = this.sharedMethods.filterRolesByOrgs(this.selectedUser);
      if (keys.length === 0 && !this.editUserError.includes(UserFormError.ROLE)) {
        this.selectedUser.roleKeys = [];
        this.editUserError.push(UserFormError.ROLEASSIGNMENT);
        formValid = false;
      } else {
        this.selectedUser.roleKeys = keys;
      }
    }

    return { valid: formValid, errorType: this.editUserError };
  }

  // check assignments when user company is external organization
  public userCompExternalAssignments() {
    if (!this.selectedUser) {
      return;
    }
    if (!this.selectedUser?.userAssignments) {
      return;
    }
    const distinctOrgKeys = [
      ...new Set(this.selectedUser.userAssignments?.map((assignment) => assignment.assignedEntityOrgKey)),
    ];
    if (distinctOrgKeys.length > 1) {
      const org = this.userStore.allCompanies.find((company) => company.key === this.selectedUser!.orgKey);
      if (org?.isCustomer) {
        this.companyError = this.$t(`umHome.error.mustBeInternal`);
      }
    } else {
      this.companyError = '';
    }
  }

  // edit user
  public toggleEdit(): void {
    this.editUserError = [];
    this.editUser = !this.editUser;
    EventBus.$emit(UmEvents.UserDetailsEditMode, this.editUser);
    this.closeAllDropDowns();
  }
  public cancelUserSave(): void {
    this.resetUser();
    this.toggleEdit();
  }

  // Helper Methods
  private closeAllDropDowns() {
    this.closeRoleDropDown();
    this.closeOrganizationDropDown();
  }
  private async handleUserSave(clearAssignments: boolean = false): Promise<void> {
    this.userStore.setUserLoaded(false);
    await this.saveUser();
    if (clearAssignments && this.selectedUser?.key != null) {
      await this.userAssignmentsStore.clearUserAssignments(this.selectedUser.key);
      this.userStore.setSelectedUserAssignments([]);
    }
    this.userStore.setUserLoaded(true);
    EventBus.$emit(UmEvents.UserDetailsEditMode, false);
    this.closeAllDropDowns();
  }
  private async saveUser() {
    this.companyError = '';
    this.validateUserDetails();
    if (this.selectedUser) {
      let validationData = userFormValidation(this.selectedUser);
      this.editUserError = validationData.errorType;
      validationData = this.checkUserAssignedRoles();
      if (validationData.valid && validationData.errorType.length === 0) {
        if (!this.companyError) {
          this.editUser = false;
          await this.userStore.updateUser(this.selectedUser);
        } else {
          this.editUser = true;
        }
      } else {
        this.editUser = true;
      }
    }
  }

  private setStatus() {
    if (this.selectedUser) {
      if (this.selectedUser.status === UserStatus.ACTIVE) {
        this.selectedUser.status = UserStatus.INACTIVE;
      } else if (this.selectedUser.status === UserStatus.PENDING) {
        this.selectedUser.status = UserStatus.DEACTIVATEPENDING;
      } else if (this.selectedUser.status === UserStatus.DEACTIVATEPENDING) {
        this.selectedUser.status = UserStatus.PENDING;
      } else {
        this.selectedUser.status = UserStatus.ACTIVE;
      }
    }
  }

  private async activateDeactivateUser(): Promise<void> {
    this.setStatus();
    this.closeActivateDeactivateModal();
    this.setModalTexts();
    if (this.selectedUser) {
      this.userStore.setUserLoaded(false);
      await this.userStore.activateDeactivateUser(this.selectedUser);
      this.userStore.setUserLoaded(true);
    }
  }

  private goToUser(): void {
    const lastModifiedByUser = this.userStore.allUsers.find(
      (user: IUser) => this.selectedUser?.lastModifiedBy === user.key
    );
    if (lastModifiedByUser?.key !== this.selectedUser?.key) {
      this.userStore.setClearFilter(true);
      const sortedUsers = orderBy(this.userStore.allUsers, ['lastModifiedAt'], ['desc']);
      const lastModifiedByUserIndex = sortedUsers.findIndex(
        (user: IUser) => this.selectedUser?.lastModifiedBy === user.key
      );
      if (lastModifiedByUser) {
        this.userStore.setIsUserLoading(true);
        this.userStore.getSelectedUserByKey(lastModifiedByUser.key ? lastModifiedByUser.key : '');
        this.userStore.setGoToUserPage(lastModifiedByUserIndex / 10);
      }
    }
  }

  private resendMail() {
    if (this.selectedUser) {
      this.userStore.resendEmail(this.selectedUser);
    }
  }

  private closeActivateDeactivateModal() {
    this.activateDeactivateModalIsOpen = false;
  }

  private openActivateDeactivateModal() {
    this.activateDeactivateModalIsOpen = true;
  }

  private closeRoleDropDown(): void {
    if (this.$refs && this.$refs.roleDropdownEdit) {
      (this.$refs.roleDropdownEdit as Vue & { closeMenu: () => void }).closeMenu();
    }
  }

  private editUserClose() {
    this.editUserError = [];
    this.closeRoleDropDown();
    this.closeOrganizationDropDown();
    this.editUser = false;
    this.companyError = '';
    this.resetUser();
    this.setModalTexts();
  }

  @Watch('userStore.selectedUser', { immediate: true })
  private onSelectedUserChanged() {
    this.editUserError = [];
    this.editUser = false;
    this.userAssignmentsInEditMode = false;
    this.closeRoleDropDown();
    this.closeOrganizationDropDown();
    this.companyError = '';
    this.setModalTexts();
  }

  @Watch('userStore.closeEditUser', { immediate: true })
  private closeEditUser(close: boolean) {
    if (close) {
      this.editUserClose();
    }
  }
}
