import { BaseProvider } from '@/utils/providers/base-provider';
import { findAppScriptSourceOrigin } from '@/utils/from-parent';
import { IConfig, Config } from '@/view-models/config';
import axios from 'axios';

class ConfigProvider extends BaseProvider<IConfig> {
  constructor() {
    super('Config');
    this.instance = new Config({
      domain: '',
      clientId: '',
      audience: '',
      redirectUriStandalone: '',
      redirectUriParent: '',
      umApiUrl: ''
    });
  }

  public async init(): Promise<void> {
    const http = axios.create({
      baseURL: '',
      timeout: 15000
    });

    const baseURL = findAppScriptSourceOrigin();
    const response = await http.get(`${baseURL}config.json`);
    let config = new Config(response.data);
    config = this.processEnvReplacements(config);
    config = this.sanitizeUrls(config);

    this.instance = config;
  }

  private processEnvReplacements(config: Config) {
    if (process.env.VUE_APP_AUTH0_DOMAIN != null) {
      config.domain = process.env.VUE_APP_AUTH0_DOMAIN;
    }
    if (process.env.VUE_APP_AUTH0_CLIENT_ID != null) {
      config.clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
    }
    if (process.env.VUE_APP_AUTH0_AUDIENCE != null) {
      config.audience = process.env.VUE_APP_AUTH0_AUDIENCE;
    }
    if (process.env.VUE_APP_AUTH0_REDIRECT_URI != null) {
      config.redirectUri = process.env.VUE_APP_AUTH0_REDIRECT_URI;
    }
    if (process.env.VUE_APP_USER_API_BASE_URL != null) {
      config.umApiUrl = process.env.VUE_APP_USER_API_BASE_URL;
    }

    return config;
  }

  private sanitizeUrls(config: Config) {
    config.umApiUrl = sanitizeUrl(config.umApiUrl, false);

    return config;
  }
}

function sanitizeUrl(url: string, withApiSuffix: boolean) {
  let sanitized = url;
  if (withApiSuffix) {
    if (sanitized.endsWith('/')) {
      sanitized += 'api';
    }
    if (!sanitized.endsWith('/api')) {
      sanitized += '/api';
    }
  } else {
    if (sanitized.endsWith('/')) {
      sanitized = sanitized.replace(/\/$/, '');
    }
    if (sanitized.endsWith('/api')) {
      sanitized = sanitized.replace(/\/api$/, '');
    }
  }

  return sanitized;
}

export default new ConfigProvider();
