import { Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store-modules';
import { IAppStore } from '@/store-modules/types/app';

@Module({
  namespaced: true,
  name: 'appStore',
  store,
  dynamic: true,
})
class AppStore extends VuexModule implements IAppStore {
  // State
  public isAppInitialized: boolean = false;

  // Mutations
  @Mutation
  public setIsAppInitialized(value: boolean): void {
    this.isAppInitialized = this.isAppInitialized || value;
  }
}

export default getModule(AppStore);
