







// Libraries
import { Component, Emit, Model, Prop, Watch, Vue } from 'vue-property-decorator';
import Fuse, { FuseOptions } from 'fuse.js';
import getSlug from 'speakingurl';
// View Models
// Components
import EventBus from '@/shared/event-bus';
// Services

@Component({
  name: 'object-search',
})
export default class ObjectSearch<T> extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public documents!: T[];

  @Prop({ default: 'key' })
  public idProperty!: string;

  @Prop({ default: () => ['name'] })
  public searchFields!: string[];

  @Prop({ required: false, default: null })
  public placeholder!: string;

  @Prop({ required: false, default: 400 })
  public timoutMillis!: number;

  @Prop({ required: false, default: true })
  public hasBorderBottom!: boolean;

  @Prop({ default: false })
  public showSearch!: boolean;

  @Prop({ default: 'Input' })
  public objectSearchId!: string;

  @Model('input', { required: true, default: null })
  public searchResults!: string[];

  @Prop({ default: () => 'Search' })
  public searchText!: string;

  // VUEX
  // Properties
  public uid: number = 0;
  public searchString: string = '';
  // Fields
  private index: Fuse<T, FuseOptions<T>> | any = null;
  private timeoutId: any = null;
  private fuseOptions: FuseOptions<T> = {
    id: 'key', // Replaced later
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0.25,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: [
      // Replaced later
      'name',
    ],
  };

  // Getters

  get clearVisible(): boolean {
    return this.searchString != null && this.searchString !== '';
  }

  public clearSearchText(): void {
    this.searchString = '';
  }

  get resolvedPlaceholder(): string {
    return this.placeholder == null ? this.$t('global.searchPlaceholder').toString() : this.placeholder;
  }

  get disableSearch(): boolean {
    return false;
  }

  // Lifecycle Handlers
  public mounted(): void {
    this.init();
    this.onSearchResultsChange(null);
    EventBus.$on('CLEAR_SEARCH_STRING', this.clearSearch);
    EventBus.$on('search-clicked', this.handleSearchClicked);
  }

  public beforeDestroy(): void {
    this.clearSearch();
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
    EventBus.$off('search-clicked', this.handleSearchClicked);
    EventBus.$off('CLEAR_SEARCH_STRING', this.clearSearch);
  }

  public updated(): void {
    this.getResults();
  }

  public handleSearchClicked() {
    this.$nextTick().then(() => {
      if (this.$refs.searchField != null) {
        const searchField: HTMLInputElement = this.$refs.searchField as HTMLInputElement;
        searchField.focus();
      }
    });
  }

  public getResults(): void {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      if (this.isNullOrWhiteSpace(this.searchString) || this.searchString.length < this.fuseOptions.minMatchCharLength!) {
        this.onSearchResultsChange(null);
      } else if (this.index != null) {
        const results: string[] = this.index.search(this.searchString.trim()) as string[];
        this.onSearchResultsChange(results);
      }
      this.timeoutId = null;
    }, this.timoutMillis);
  }

  public isNullOrWhiteSpace(input: string): boolean {
    if (typeof input === 'undefined' || input == null) {
      return true;
    }
    return input.replace(/\s/g, '').length < 1;
  }

  public init(): void {
    if (this.documents != null && this.idProperty != null && this.idProperty !== '' && this.searchFields != null && this.searchFields.length > 0) {
      const documents: T[] = this.documents;
      const idProperty: string = this.idProperty;
      const searchFields: string[] = this.searchFields.slice(0);
      this.$nextTick(() => {
        const searchDocuments: T[] = [];
        documents.forEach((document: any) => {
          const newDoc: any = Object.assign({}, document);
          searchFields.forEach((field: string) => {
            newDoc[`${field}_slug`] = getSlug(document[field] != null ? document[field].toString() : '', {
              separator: ' ',
              mark: true,
              uric: true,
              uricNoSlash: true,
              maintainCase: true,
            });
          });
          searchDocuments.push(newDoc);
        });
        searchFields.forEach((field: string) => {
          if (!searchFields.includes(`${field}_slug`)) {
            searchFields.push(`${field}_slug`);
          }
        });
        this.fuseOptions.keys = searchFields;
        this.fuseOptions.id = idProperty;
        this.index = new Fuse(searchDocuments, this.fuseOptions);
      });
    }
  }

  // Helper Methods
  // Event Methods
  public clearSearch(): void {
    this.searchString = '';
  }

  public focusSearch(): void {
    const input: HTMLInputElement = this.$refs.searchFocus as HTMLInputElement;
    input.focus();
  }

  // Watchers
  @Watch('documents', { deep: true })
  public onDocumentsChange(newDocuments: object[]): void {
    if (newDocuments != null) {
      this.init();
    }
  }

  @Watch('idProperty')
  public onIdPropertyChange(newIdProperty: string, oldIdProperty: string): void {
    if (newIdProperty != null && (oldIdProperty == null || newIdProperty !== oldIdProperty)) {
      this.init();
    }
  }

  @Watch('searchFields')
  public onSearchFieldsChange(newSearchFields: string[]): void {
    if (newSearchFields != null) {
      this.init();
    }
  }

  @Watch('searchString')
  public onSearchStringChange(value: string): void {
    this.getResults();
    this.onSearchChange(value);
  }

  @Watch('showSearch')
  public onShowSearchChange(): void {
    this.focusSearch();
  }

  // Emitters
  @Emit('input')
  public onSearchResultsChange(unused: string[] | any): void {
    /* */
  }

  @Emit('iconClick')
  public iconClick(unused: string[]): void {
    /* */
  }

  @Emit('onSearchChange')
  public onSearchChange(unused: string): void {
    /* */
  }
}
