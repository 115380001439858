






import { Component } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';

@Component({
  name: 'page-not-found'
})
export default class PageNotFound extends BaseComponent {

}
