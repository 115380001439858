import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store-modules';
import { UsersService } from '@/services/user-service';
import { IAssignmentTreeItemViewModel } from '@/view-models/assignment-model';
import { alphabeticSorter } from '@/utils/array-utils';
import { ICustomer } from '@/components/types/select-box';
import { IUserAssignmentsStore } from '../types/userAssignments';
import Vue from 'vue';
import { IUser, IUserAssignment, IUserAssignments } from '@/view-models/user-model';

@Module({
  namespaced: true,
  name: 'userAssignmentsStore',
  store,
  dynamic: true,
})
class UserAssignmentsStore extends VuexModule implements IUserAssignmentsStore {
  public containsInaccessibleAssignments: boolean = false;
  public currentCustomerKey: string = null!;
  public assignableCustomers: ICustomer[] = [];
  public assignableAssignments: IAssignmentTreeItemViewModel[] = [];

  public get rootLevelAssignments(): IAssignmentTreeItemViewModel[] {
    return this.assignableAssignments
      .filter((a: IAssignmentTreeItemViewModel) => a.parentKey == null)
      .sort(alphabeticSorter((a: IAssignmentTreeItemViewModel) => a.name));
  }

  public get currentCustomerRootLevelAssignment(): IAssignmentTreeItemViewModel {
    return this.assignableAssignments.find((assignment) => assignment.key === this.currentCustomerKey)!;
  }

  public get currentCustomerAssignableAssignments(): IAssignmentTreeItemViewModel[] {
    return this.assignableAssignments.filter(
      (assignment) => assignment.key === this.currentCustomerKey || assignment.orgKey === this.currentCustomerKey
    )!;
  }

  public get currentCustomerVisibleAssignments(): IAssignmentTreeItemViewModel[] {
    return this.assignableAssignments.filter(
      (assignment) =>
        (assignment.key === this.currentCustomerKey || assignment.orgKey === this.currentCustomerKey) &&
        assignment.isVisible
    )!;
  }

  public get childrenAssignments() {
    return (parentKey: string): IAssignmentTreeItemViewModel[] => {
      return this.assignableAssignments
        .filter((a: IAssignmentTreeItemViewModel) => a.parentKey === parentKey)
        .sort(alphabeticSorter((a: IAssignmentTreeItemViewModel) => a.name));
    };
  }

  public get hasAssignedAssignments(): boolean {
    return this.assignableAssignments.some((assignment) => assignment.isAssigned);
  }

  public get userAssignedAssignments() {
    return (user?: IUser): IUserAssignment[] => {
      const userAssignments: IUserAssignment[] = user?.userAssignments ?? [];
      this.assignableAssignments.forEach((assignment) => {
        const existingAssignmentIndex = userAssignments.findIndex(
          (existing) => existing.assignedEntityKey === assignment.key
        );
        // If it not an existing user's assignment but was newly assigned then add it
        if (existingAssignmentIndex === -1 && assignment.isAssigned) {
          userAssignments.push({
            userKey: user?.key,
            assignedEntityKey: assignment.key,
            assignedEntityOrgKey: assignment.orgKey,
            assignedEntityType: assignment.type,
          });
        // If it is an existing user's assignment but was unassigned then remove it
        } else if (existingAssignmentIndex > -1 && !assignment.isAssigned) {
          userAssignments.splice(existingAssignmentIndex, 1);
        }
      });
      return userAssignments;
    };
  }

  // Mutations
  @Mutation
  public setAssignedAssignments(key: string): void {
    if (this.assignableAssignments && this.assignableAssignments.length > 0) {
      // set assigned and visible properties based on assignable assignments
      const assignmentIndex = this.assignableAssignments.findIndex((item) => item.key === key);
      if (assignmentIndex > -1) {
        const parent = this.assignableAssignments.find(
          (assignment) => assignment.key === this.assignableAssignments[assignmentIndex].parentKey
        );

        // Only set the children if parent is not assigned
        if (!parent?.isAssigned) {
          Vue.set(this.assignableAssignments[assignmentIndex], 'isAssigned', true);
        }

        // if parent is assigned then set children unassigned
        setChildrenAssignableRecursive(this.assignableAssignments, this.assignableAssignments[assignmentIndex], false);

        Vue.set(this.assignableAssignments[assignmentIndex], 'isVisible', true);
        // get all the ancestors and set to visible in order to show them in tree hierarchy
        setAncestorsVisibilityRecursive(this.assignableAssignments, this.assignableAssignments[assignmentIndex]);
        setChildrenVisibilityRecursive(this.assignableAssignments, this.assignableAssignments[assignmentIndex], true);
      } else {
        this.containsInaccessibleAssignments = true;
      }
    }
  }

  @Mutation
  public openAllAssignments(): void {
    this.assignableAssignments.forEach((assignment) => (assignment.isOpen = true));
  }

  @Mutation
  public setAssignableAssignments(items: IAssignmentTreeItemViewModel[]): void {
    this.assignableAssignments = items;
  }

  @Mutation
  public setAssignableCustomers(assignableCustomers: ICustomer[]): void {
    this.assignableCustomers = assignableCustomers;
  }

  @Mutation
  public setContainsInaccessibleAssignments(value: boolean): void {
    this.containsInaccessibleAssignments = value;
  }

  @Mutation
  public setCurrentCustomerKey(key: string): void {
    this.currentCustomerKey = key;
  }

  @Mutation
  public toggleAssignedAssignment(assignment: IAssignmentTreeItemViewModel): void {
    const assignmentIndex = this.assignableAssignments.indexOf(assignment);
    const toggle = !assignment.isAssigned;
    Vue.set(this.assignableAssignments[assignmentIndex], 'isAssigned', toggle);
    Vue.set(this.assignableAssignments[assignmentIndex], 'isVisible', toggle);

    // toggle ancestors visibility based on if there are other assignments under those ancestors
    if (assignment.parentKey) {
      setAncestorsVisibilityRecursive(this.assignableAssignments, assignment);
    }

    // if the parent is assigned then all children should be unassigned regardless
    setChildrenAssignableRecursive(this.assignableAssignments, assignment, false);
    // if the parent visibility is toggled children should be as well
    setChildrenVisibilityRecursive(this.assignableAssignments, assignment, toggle);
  }

  @Mutation
  public updateAssignableAssignments(item: IAssignmentTreeItemViewModel): void {
    const index: number = this.assignableAssignments.findIndex((asset: any) => asset.key === item.key);
    if (index > -1) {
      this.assignableAssignments.splice(index, 1, item);
    }
  }

  @Mutation
  public resetAssignedAssignments(): void {
    this.assignableAssignments.forEach((unused, index) => {
      Vue.set(this.assignableAssignments[index], 'isAssigned', false);
      Vue.set(this.assignableAssignments[index], 'isVisible', false);
    });
  }

  // Actions
  @Action({ rawError: true })
  public async loadAssignableAssignments(currentUser?: IUser): Promise<void> {
    if (currentUser == null) {
      return;
    }

    const service = await UsersService.factory();
    const assignments: IAssignmentTreeItemViewModel[] = await service.getCurrentUserAssignmentTree();

    let assignableAssignments: IAssignmentTreeItemViewModel[] = [];
    if (currentUser.isAdminCompanyUser && currentUser.isSuperUser) {
      assignments.forEach((assignment) => (assignment.isAssignable = true));
      assignableAssignments = assignments;
    } else {
      // filter those assignments with only that are assignable by current user
      // and their ancestor to build the tree hierarchy
      const userAssignmentKeys = currentUser.userAssignments?.map(
        (userAssignment: IUserAssignment) => userAssignment.assignedEntityKey
      )!;
      for (const userAssignmentKey of userAssignmentKeys) {
        const existing = assignableAssignments.find((assignable) => assignable.key === userAssignmentKey);
        if (existing) {
          existing.isAssignable = true;
        } else {
          const current = assignments.find((assignment) => assignment.key === userAssignmentKey);
          if (current) {
            current.isAssignable = true;
            assignableAssignments.push(current);

            // find the ancestors (if any) and add them
            addAncestorsRecursive(assignments, current, assignableAssignments);
            // find the children (if any) and add them
            addChildrenRecursive(assignments, current, assignableAssignments);
          }
        }
      }
    }

    this.setCurrentCustomerKey(currentUser.activeCustomerKey!);
    this.setAssignableAssignments(assignableAssignments);
  }

  @Action({ rawError: true })
  public async loadAssignableCustomers(): Promise<void> {
    const usersService = await UsersService.factory();
    await usersService.getAllAssignedCustomers().then((response) => {
      this.setAssignableCustomers(response);
    });
  }

  @Action({ rawError: true })
  public async updateUserAssignments(user: IUser): Promise<IUserAssignment[]> {
    const userAssignments: IUserAssignments = {
      key: user.key!,
      userAssignments: this.userAssignedAssignments(user),
    };

    const usersService = await UsersService.factory();
    const result = await usersService.updateUserAssignments(userAssignments);
    return result.userAssignments;
  }

  @Action({ rawError: true })
  public async clearUserAssignments(userKey: string): Promise<void> {
    const usersService = await UsersService.factory();
    await usersService.updateUserAssignments({
      key: userKey,
      userAssignments: [],
    });
  }
}

export default getModule(UserAssignmentsStore);

function addAncestorsRecursive(
  pool: IAssignmentTreeItemViewModel[],
  current: IAssignmentTreeItemViewModel,
  destination: IAssignmentTreeItemViewModel[]
) {
  if (current) {
    const ancestor = pool.find((item) => item.key === current.parentKey)!;
    if (ancestor) {
      if (!destination.find((item) => item.key === ancestor.key)) {
        destination.push(ancestor);
      }
      current = ancestor;
      addAncestorsRecursive(pool, current, destination);
    }
  }
}

function addChildrenRecursive(
  pool: IAssignmentTreeItemViewModel[],
  current: IAssignmentTreeItemViewModel,
  destination: IAssignmentTreeItemViewModel[]
) {
  if (current) {
    const children = pool.filter((item) => item.parentKey === current.key)!;
    for (const child of children) {
      if (!destination.find((item) => item.key === child.key)) {
        child.isAssignable = true;
        destination.push(child);
      }
      current = child;
      addChildrenRecursive(pool, current, destination);
    }
  }
}

function setAncestorsVisibilityRecursive(
  pool: IAssignmentTreeItemViewModel[],
  current: IAssignmentTreeItemViewModel
): void {
  const ancestorVisible = pool.some((assignable) => assignable.parentKey === current.parentKey && assignable.isVisible);
  current = pool.find((item) => item.key === current.parentKey)!;
  if (current) {
    current.isVisible = ancestorVisible;
    if (current.parentKey) {
      setAncestorsVisibilityRecursive(pool, current);
    }
  }
}

function setChildrenVisibilityRecursive(
  pool: IAssignmentTreeItemViewModel[],
  current: IAssignmentTreeItemViewModel,
  visible: boolean
): void {
  const children = pool.filter((item) => item.parentKey === current.key)!;
  for (const child of children) {
    child.isVisible = visible;
    current = child;
    setChildrenVisibilityRecursive(pool, current, visible);
  }
}

function setChildrenAssignableRecursive(
  pool: IAssignmentTreeItemViewModel[],
  current: IAssignmentTreeItemViewModel,
  assigned: boolean
): void {
  const children = pool.filter((item) => item.parentKey === current.key)!;
  for (const child of children) {
    child.isAssigned = assigned;
    current = child;
    setChildrenAssignableRecursive(pool, current, assigned);
  }
}
