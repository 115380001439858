











import { Component } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import AppStore from '@/store-modules/appStore/appStore';

@Component({
  name: 'nav-tab',
  components: { }
})
export default class NavTab extends BaseComponent {
    public appStore = AppStore;
 }
