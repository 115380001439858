export enum UserFormError {
   FNAME = 'firstName',
   LNAME = 'lastName',
   EMAIL = 'email',
   VALIDATEEMAIL = 'validateEmail',
   ROLE = 'role',
   FNAMECHAR = 'firstNameChar',
   LNAMECHAR = 'lastNameChar',
   ROLEASSIGNMENT = 'roleAssignment',
   MUSTBEINTERNAL = 'mustBeInternal'
}
