import { render, staticRenderFns } from "./OnpointModal.vue?vue&type=template&id=1a910138&scoped=true&"
import script from "./OnpointModal.vue?vue&type=script&lang=ts&"
export * from "./OnpointModal.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./OnpointModal.vue?vue&type=style&index=0&id=1a910138&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1a910138",
  null
  ,true
)

export default component.exports