export interface IAssignmentTreeItemViewModel extends IEntityViewModel {
    key: string;
    parentKey: string;
    type: AssetListItemTypeEnum;

    // Client Only
    isOpen?: boolean;
    isAssignable?: boolean;
    isAssigned?: boolean;
    isVisible?: boolean;
}

export interface IEntityViewModel {
    key: string;
    name: string;
    orgKey: string;
    createdAt: string;
    createdBy: string;
    lastModifiedAt?: string;
    lastModifiedBy?: string;
    searchName?: string;
    currentError: string;
}

export enum AssetListItemTypeEnum {
    Customer = 'Customer',
    CustomerSite = 'CustomerSite',
    SiteGroup = 'SiteGroup',
    Asset = 'Asset',
    AssetGroup = 'AssetGroup'
}

export enum CheckedState {
    Checked = 'Checked',
    None = 'None',
    Partial = 'Partial',
    Unchecked = 'Unchecked'
}
