import Vue from 'vue';

const EventBus = new Vue();
export default EventBus;

export enum UmEvents {
  ClearSearch = 'CLEAR_SEARCH_STRING',
  SearchClicked = 'search-clicked',
  UserAssignmentsEditMode = 'userAssignments::editMode',
  UserDetailsEditMode = 'userDetails::editMode'
}

export enum ParentEvents {
  RouteChange = 'onpoint::routeChange',
  ActiveCustomerChanged = 'user::ActiveCustomerChanged'
}

export function configureEventBuses() {
  EventBus.$off();
}

export interface ISubscription {
  unsubscribe: () => void;
}

export const subscription = (event: UmEvents, callback: (...args: any[]) => void): ISubscription => {
  EventBus.$on(event, callback);

  return {
    unsubscribe() {
      EventBus.$off(event, callback);
    }
  };
};
