













import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import OnpointModal from '../common/OnpointModal.vue';
import ErrorStore from '@/store-modules/errorStore/errorStore';

Vue.use(BootstrapVue);
@Component({
  components: {OnpointModal}
})
export default class Error extends Vue {

  private errorStore = ErrorStore;

  get error(): void {
    return this.errorStore.getError;
  }

  // clear error
  private clearError(): void {
    ErrorStore.clearError();
  }

}
