




















































// Libraries
import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
// View Models
// Components
import Dropdown from '@/components/common/Dropdown.vue';
import ObjectSearch from '@/components/common/ObjectSearch.vue';
import { ICustomer } from '../types/select-box';
import { orderBy } from 'lodash';

@Component({
  name: 'available-customers-dropdown',
  components: { Dropdown, ObjectSearch },
})
export default class AvailableCustomersDropdown extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public value!: ICustomer | null;
  @Prop({ required: true})
  public customers!: ICustomer[];
  @Prop({ default: false })
  public autoSelectFirst!: boolean;

  // Refs
  @Ref('dropdown')
  public dropdownRef!: Dropdown;

  // Properties
  public searchResults: ICustomer[] = [];
  public sortedCustomers: ICustomer[] = [];

  // Fields
  public inSearchMode: boolean = false;

  // Getters
  public get searchedCustomers(): string[] | null {
    return [];
  }
  public set searchedCustomers(results: string[] | null) {
    this.inSearchMode = results != null;
    this.searchResults = this.customers.filter((customer) => results?.includes(customer.key));
  }
  public get visibleCustomers(): ICustomer[] {
    this.sortCustomers();
    return this.inSearchMode ? this.searchResults : this.sortedCustomers;
  }
  public sortCustomers() {
    this.sortedCustomers = orderBy(this.customers, [(customer: ICustomer) => customer.name.toLowerCase()], ['asc']);
  }
  public get dropdownText(): string {
    return this.value?.name ?? this.$t('umHome.manageUserModal.selectCustomer').toString();
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    if (this.autoSelectFirst && this.customers.length > 0) {
      this.emitChange(this.customers[0]);
    } else if (this.value) {
      this.emitChange(this.value);
    }
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('input')
  public emitChange(customerEmit: ICustomer) {
    this.dropdownRef.toggleMenu();
  }
}
