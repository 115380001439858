export enum UserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DEACTIVATEPENDING = 'DeactivatePending',
  PENDING = 'Pending'
}

export const userStatusMapper = new Map<string, number>([
  [UserStatus.INACTIVE, 0],
  [UserStatus.ACTIVE, 1],
  [UserStatus.DEACTIVATEPENDING, 2],
  [UserStatus.PENDING, 3],
]);
