export class PromiseProvider<T> {
  protected internalInstance!: Promise<T>;
  protected readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

  get instance(): Promise<T> {
    return this.internalInstance;
  }
}
