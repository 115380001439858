

































































































// Libraries
import { Component, Prop, Vue } from 'vue-property-decorator';
// Helpers
import EventBus, { UmEvents } from '@/shared/event-bus';
// Components
import Loading from '@/components/common/Loading.vue';
import ObjectSearch from '@/components/common/ObjectSearch.vue';
import UserAssignmentsTreeView from '@/components/tree/UserAssignmentsTreeView.vue';
import AvailableCustomersDropdown from '@/components/user/AvailableCustomersDropdown.vue';
// Contracts
import { ICustomer } from '../types/select-box';
import { IAssignmentTreeItemViewModel } from '@/view-models/assignment-model';
// Stores
import userAssignmentsStore from '@/store-modules/userStore/userAssignmentsStore';
import userStore from '@/store-modules/userStore/userStore';

@Component({
  name: 'edit-user-assignments',
  components: {
    UserAssignmentsTreeView,
    ObjectSearch,
    Loading,
    AvailableCustomersDropdown,
  },
})
export default class EditUserAssignments extends Vue {
  // VUE.JS Props
  @Prop({ default: false })
  public isNewUserContext!: boolean;

  // Refs
  public assetKeys: string[] = [];
  public searchedAssignableResults: IAssignmentTreeItemViewModel[] = [];
  public searchedAssignedResults: IAssignmentTreeItemViewModel[] = [];
  public isSaving: boolean = false;

  // Fields
  private inAssignableSearchMode: boolean = false;
  private inAssignedSearchMode: boolean = false;
  private selectedCustomerValue: ICustomer | undefined = this.assignableCustomers?.[0];

  // VUEX
  public get assignableCustomers(): ICustomer[] {
    let customers: ICustomer[] = [];
    const userOrgKey = userStore.selectedUser?.orgKey;
    if (userOrgKey) {
      if (userStore.internalCompanies.find((comp) => comp.key === userOrgKey)) {
        customers = userStore.customerCompanies;
      } else {
        customers = userStore.customerCompanies.filter((comp) => comp.key === userOrgKey);
      }
    }

    return customers;
  }

  public get currentCustomerRootLevelAssignment() {
     return [userAssignmentsStore.currentCustomerRootLevelAssignment];
  }

  public get currentAssignableAssignments(): IAssignmentTreeItemViewModel[] {
    return userAssignmentsStore.currentCustomerAssignableAssignments;
  }

  public get currentAssignedAssignments(): IAssignmentTreeItemViewModel[] {
    return userAssignmentsStore.currentCustomerVisibleAssignments;
  }

  public get customerAssignedRootLevelAssignments(): IAssignmentTreeItemViewModel[] {
    if (this.inAssignedSearchMode) {
      return this.searchedAssignedResults;
    } else {
      return this.currentCustomerRootLevelAssignment;
    }
  }

  public get customerRootLevelAssignments(): IAssignmentTreeItemViewModel[] {
    if (this.inAssignableSearchMode) {
      return this.searchedAssignableResults;
    } else {
      return this.currentCustomerRootLevelAssignment;
    }
  }

  // Getters
  public get selectedCustomer(): ICustomer | undefined {
    return this.selectedCustomerValue;
  }

  public set selectedCustomer(selected: ICustomer | undefined) {
    this.selectedCustomerValue = selected;
    if (selected) {
      userAssignmentsStore.setCurrentCustomerKey(selected.key);
    }
  }

  public get searchedAssignableKeys(): string[] {
    return [];
  }

  public setSearchedAssignableKeys(value: string[]) {
    let result: IAssignmentTreeItemViewModel[] = [];
    if (value) {
      result = userAssignmentsStore.assignableAssignments.filter((item) => value.includes(item.key));
      this.searchedAssignableResults = result;
      this.inAssignableSearchMode = true;
    } else {
      this.inAssignableSearchMode = false;
    }
  }

  public setSearchedAssignedKeys(value: string[]) {
    let result: IAssignmentTreeItemViewModel[] = [];
    if (value) {
      result = userAssignmentsStore.assignableAssignments.filter((assignment) => value.indexOf(assignment.key) !== -1);
      this.searchedAssignedResults = result;
      this.inAssignedSearchMode = true;
    } else {
      this.inAssignedSearchMode = false;
    }
  }

  public get hasAssignedAssignments(): boolean {
    return userAssignmentsStore.hasAssignedAssignments;
  }

  // Event Methods
  public async save(): Promise<void> {
    this.isSaving = true;
    if (userStore.selectedUser) {
      const userAssignments = await userAssignmentsStore.updateUserAssignments(userStore.selectedUser);
      userStore.setSelectedUserAssignments(userAssignments);
    }
    this.$emit('close-edit-mode-user-assignments');
    EventBus.$emit(UmEvents.UserAssignmentsEditMode, false);
    this.isSaving = false;
  }

  public cancel(): void {
    this.$emit('close-edit-mode-user-assignments');
    EventBus.$emit(UmEvents.UserAssignmentsEditMode, false);
  }
}
