import { AxiosInstance } from 'axios';
import { ICustomer } from '@/components/types/select-box';
import { ICompany, IUser, IUserAssignments, IUserRole } from '@/view-models/user-model';
import { umHttp } from './common/http';
import ControllerBaseService from './common/controller-base-service';
import { IAssignmentTreeItemViewModel } from '@/view-models/assignment-model';

export class UsersService extends ControllerBaseService {

  public static async factory(): Promise<UsersService> {
    return new UsersService((await umHttp.instance));​​
  }

  constructor(axiosInstance: AxiosInstance) {
    super('users', axiosInstance);
  }

  public getAllUsers(): Promise<IUser[]> {
    return this.httpGet<IUser[]>();
  }

  public getAllCompanies(): Promise<ICompany[]> {
    return this.httpGet<ICompany[]>('assignedOrganizations');
  }

  public getAllAssignedCustomers(): Promise<ICustomer[]> {
    return this.httpGet<ICustomer[]>('assignedCustomers');
  }

  public updateUser(user: IUser | null): Promise<IUser> {
    return this.httpPut<IUser>('', user);
  }

  public updateUserAssignments(userAssignments: IUserAssignments): Promise<IUserAssignments> {
    return this.httpPut<IUserAssignments>('assignments', userAssignments);
  }

  public addUser(user: IUser): Promise<IUser> {
    return this.httpPost<IUser>('', user);
  }

  public activateDeactivateUser(user: IUser, statusSent: number | undefined): Promise<IUser> {
    const status = statusSent ?? 0;
    return this.httpPut<IUser>(`${user.key}/updateStatus/${status}`);
  }

  public resendEmail(user: IUser): Promise<IUser> {
    return this.httpPost<IUser>(`${user.key}/resend-email`);
  }

  public getUserCurrent(): Promise<IUser> {
    return this.httpGet<IUser>('current');
  }

  public getCurrentUserAssignmentTree(): Promise<IAssignmentTreeItemViewModel[]> {
    return this.httpGet<IAssignmentTreeItemViewModel[]>('current/AssignmentTree', { onlyForActiveCustomer: false });
  }

  public async getUserByKey(key: string): Promise<IUser> {
    return this.httpGet<IUser>(key);
  }

  public getUsersByRole(roleKey: string): Promise<IUser[]> {
    return this.httpGet<IUser[]>(`by-role/${roleKey}`);
  }

  // TODO: Fix the implementation of this; it is looped through for every user, even when only one user is removed from the list
  public async assignUserToRoles(userRole: IUserRole): Promise<any> {
    return this.httpPatch('', { id: userRole.userKey },
      [
        {
          op: 'replace',
          path: '/roleKeys',
          value: userRole.roleKeys
        }
      ]
    );
  }
}
