
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {
  @Prop({ required: true })
  private dropdownId!: string;
  @Prop({ required: false })
  private relative!: boolean;
  @Prop({ required: false })
  private iconDirection!: string;
  @Prop({ required: false })
  private nofloatIcon!: boolean;
  @Prop({ required: false })
  private width!: string;
  @Prop({ required: false, default: true })
  private enableToggleAction!: boolean;
  private showDropdown: boolean = false;

  public mounted() {
    window.addEventListener('click', () => {
      this.showDropdown = false;
    });
  }

  public handleDropdown(ev: Event) {
    ev.stopPropagation();
  }

  public toggleMenu(): void {
    if (this.enableToggleAction) {
      this.showDropdown = !this.showDropdown;
    }
  }

  public async closeMenu(ms?: number): Promise<void> {
    if (ms) { await this.delay(ms); }
    this.showDropdown = false;
  }

  private delay(ms: number): Promise<NodeJS.Timeout> {
    return new Promise( (resolve) => setTimeout(resolve, ms) );
  }
}
