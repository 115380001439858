import { UserFormError } from '@/enums/user-form';
import { IUser } from '@/view-models/user-model';

export function userFormValidation(user: IUser): { valid: boolean; errorType: UserFormError[] } {
  let formValid = false;
  const error: UserFormError[] = [];
  if (user) {
    const fNameBlank = user?.firstName.length === 0;
    const emailBlank = user?.email.length === 0;
    const emailValidate = validateEmail(user?.email);
    const roleBlank = (!user.roleKeys || user.roleKeys.length === 0);

    formValid = !fNameBlank && !emailBlank && !emailValidate && !roleBlank;

    if (formValid) {
      return { valid: formValid, errorType: error };
    }

    if (roleBlank) {
      error.push(UserFormError.ROLE);
    }

    if (emailBlank) {
      error.push(UserFormError.EMAIL);
    } else if (emailValidate) {
      error.push(UserFormError.VALIDATEEMAIL);
    }

    if (fNameBlank) {
      error.push(UserFormError.FNAME);
    }
  }
  return { valid: formValid, errorType: error };
}

function validateEmail(inputText: string) {
  const mailformat = /\S+@\S+\.\S+/;
  if (inputText.match(mailformat)) {
    return false;
  } else {
    return true;
  }
}
