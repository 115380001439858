
import BootstrapVue, { BootstrapVueIcons, DropdownPlugin } from 'bootstrap-vue';
import { Vue, Component } from 'vue-property-decorator';
import { elementId } from '@/shared/components-utils';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(DropdownPlugin);

@Component({
  name: 'BaseComponent'
})

export default class BaseComponent extends Vue {
  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;
}
