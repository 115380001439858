import config from '@/config';
import { BaseProvider } from './providers/base-provider';
import errorStore from '@/store-modules/errorStore/errorStore';
import {
  getParentAppSdk,
  IAuthClient,
  authClient,
  inParentApp,
  setupCallbackHandler
} from './from-parent';

class AuthProvider extends BaseProvider<IAuthClient> {
  constructor() {
    super('Auth Client');
  }

  public async init(): Promise<void> {
    let client = getParentAppSdk()?.authClient;

    if (client == null) {
      client = authClient;
      await client.init({
        authDomain: config.instance.domain,
        authClientID: config.instance.clientId,
        authAudience: config.instance.audience,
        authRedirectUri: config.instance.redirectUri
      });
    }

    if (!inParentApp()) {
      setupCallbackHandler(client, (error: any) => {
        const errorString = 'Authorization error. \n' + JSON.stringify(error);
        errorStore.setError({
          error: {
            message: errorString,
            error
          }
        });
      });
    }

    this.instance = client;
  }
}

const authLocator = new AuthProvider();

export default authLocator;

export const authGuard = async (toEmit: any, fromEmit: any, next: () => void) => {
  // Silently check for the presence of a token
  authLocator.instance.getAuthToken().then(() => {
    next();
  }).catch(() => {
    authLocator.instance.loginWithRedirect();
  });
};

export async function authInterceptor(settings: any) {
  try {
    const token = await authLocator.instance.getAuthToken();

    // Do not add auth token when uploading direct to S3
    if (!settings.url.includes('s3.amazonaws.com')) {
      const header = 'Authorization';
      settings.headers[header] = `Bearer ${token}`;
    }
  } catch (error) {
    authLocator.instance.loginWithRedirect();
  }

  return settings;
}
