import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { authInterceptor } from '@/utils/auth';
import errorStore from '@/store-modules/errorStore/errorStore';
import i18n from '@/config/i18n';
import { PromiseProvider } from '@/utils/providers/promise-provider';
import config from '@/config';

class HttpProvider extends PromiseProvider<AxiosInstance> {
  constructor(private getBaseUrl: () => string) {
    super('UM Http Client');
    this.internalInstance = this.init();
  }

  private init(): Promise<AxiosInstance> {
    return new Promise((resolve) => {
      const instance = axios.create({
        baseURL: '',
        timeout: 15000,
      });

      // runs on errors and timeouts
      axiosRetry(instance, {
        retries: 1,
        shouldResetTimeout: true,
        retryCondition: (error) => {
          if (error.code === 'ECONNABORTED') {
            // If a timeout occurs, try again
            const seconds = Number(error.config.timeout) / 1000;
            const message = i18n.instance
              .t('umHome.retry.errorMessageTemplate', {
                url: error.config.url,
                seconds,
              })
              .toString();

            errorStore.setError({
              error: {
                error,
                ExceptionMessage: message,
                id: i18n.instance.t('umHome.retry.errorCode').toString(),
              },
            });
          } else {
            // If not a timeout, don't bother retrying
            return false;
          }
          return true;
        },
      });

      instance.interceptors.request.use((axiosConfig) => {
        const expectedUrl = this.getBaseUrl();
        instance.defaults.baseURL = expectedUrl;
        axiosConfig.baseURL = expectedUrl;

        return axiosConfig;
      });
      instance.interceptors.request.use(authInterceptor);

      resolve(instance);
    });
  }
}

const umHttp: HttpProvider = new HttpProvider(() => config.instance.umApiUrl);
export { umHttp };
