
















import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Loading from '@/components/common/Loading.vue';
import UserList from '@/components/user/UserList.vue';
import UserDetails from '@/components/user/UserDetails.vue';
import UserStore from '@/store-modules/userStore/userStore';
import UserAssignmentsStore from '@/store-modules/userStore/userAssignmentsStore';
import errorStore from '@/store-modules/errorStore/errorStore';
import { ParentEvents } from '@/shared/event-bus';
import { parentEventBus } from '@/utils/from-parent';

@Component({
  name: 'user-management',
  components: {
    Loading,
    UserList,
    UserDetails,
  },
})
export default class UserManagement extends Vue {
  public userStore = UserStore;
  public userAssignmentsStore = UserAssignmentsStore;
  public stopLoader: boolean = false;

  public get showLoader(): boolean {
    return !this.stopLoader;
  }

  public async mounted() {
    // Handle route changes from parent
    parentEventBus()?.$on(ParentEvents.RouteChange, this.handleRouteChange);

    try {
      await this.userStore.getUserCurrent();
      await this.userStore.getAllCompanies();
      await this.userStore.getAllRoles();
      await this.userStore.getAllUsers();
      await this.userAssignmentsStore.loadAssignableCustomers();
      await this.userAssignmentsStore.loadAssignableAssignments(this.userStore.currentUser ?? undefined);
      this.userStore.setUserLoaded(true);
    } catch (error) {
      errorStore.setError({
        error: {
          error,
          ExceptionMessage: 'A problem occurred loading users.'
        }
      });
    } finally {
      this.stopLoader = true;
    }
  }

  public handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?admin\/user-management\//)) {
      const currentRelativeRoute = parentRoute.path.replace(/^\/?admin\/user-management\//, '/');
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  public beforeDestroy() {
    parentEventBus()?.$off(ParentEvents.RouteChange, this.handleRouteChange);
  }
}
