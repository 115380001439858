












import { Route } from 'vue-router';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import i18n from '@/config/i18n';
import Loading from '@/components/common/Loading.vue';
import AppReady from '@/components/mixins/app-ready';
import Error from '@/components/common/Error.vue';
import appStore from '@/store-modules/appStore/appStore';
import NavTab from '@/components/user/NavTab.vue';
import config from '@/config';
import auth from '@/utils/auth';
import router, { PagesEnum } from '@/router';
import vuePlugins from '@/plugins';
import { configureEventBuses, ParentEvents } from './shared/event-bus';
import { parentEventBus } from './utils/from-parent';

// Initialization that need to happen before the component is created
// Internationalization/Localization
i18n.init();

@Component({
  name: 'app',
  router: router.instance,
  components: {
    Loading,
    Error,
    NavTab
  },
  i18n: i18n.instance
})
export default class App extends Mixins(AppReady) {
  @Prop({ default: false })
  private isStandAlone!: boolean;

  public get isLoading() {
    return !appStore.isAppInitialized;
  }

  public get useStandAloneRoutes() {
    return this.isStandAlone && !this.isLoading;
  }

  public get showNav() {
    return this.isStandAlone && this.$route.name !== PagesEnum.home && this.useStandAloneRoutes;
  }

  public async ready() {
    if (this.isStandAlone && this.$route.name !== PagesEnum.home) {
      this.$router.push({ name: PagesEnum.home });
    }
    // Handle route changes from parent
    parentEventBus()?.$on(ParentEvents.RouteChange, this.handleRouteChange);
  }

  public async created() {
    try {
      appStore.setIsAppInitialized(false);

      // Config
      await config.init();
      // Auth
      await auth.init();
      // Events Buses
      configureEventBuses();
      // Router
      router.init();
      // Vue Plugins
      vuePlugins();

    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('Error Loading User Management App', err);
    } finally {
      appStore.setIsAppInitialized(true);
    }
  }

  public destroyed() {
    parentEventBus()?.$off(ParentEvents.RouteChange, this.handleRouteChange);
  }

  public handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?admin\/user-management\//)) {
      const currentRelativeRoute = parentRoute.path.replace(/^\/?admin\/user-management\//, '/');
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  private deactivated() {
    // Get rid of old vue instance
    this.$destroy();
    // Used when integrated into parent app
    parentEventBus()?.$off(ParentEvents.RouteChange, this.handleRouteChange);
  }
}
