










import { Component } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';

@Component({
  name: 'error-page'
})
export default class ErrorPage extends BaseComponent {

}
