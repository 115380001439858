






import { Component } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { numericSorter } from '@/utils/array-utils';
import { MenuArea, MenuItem, MenuSection } from '@/view-models/nav-section';
import NavSection from '@/components/user/NavSection.vue';

@Component({
  name: 'home-page',
  components: {
    NavSection
  }
})
export default class HomePage extends BaseComponent {
  public get menuAreas(): MenuArea[] {
    const adminSection: MenuSection = { type: 'main', text: 'global.nav.main', order: 0, show: true };
    let areas: MenuArea[] = [
      {
        section: adminSection,
        image: null,
        items: [
          {
            section: adminSection,
            text: 'global.title',
            name: 'User Managament',
            to: '/',
            id: this.elementId('mainNavigation', 'userManagement'),
            icon: { fa: 'mdi mdi-account-cog' },
            order: 1,
            exact: false,
            show: true
          },
          {
            section: adminSection,
            text: 'global.roleTitle',
            name: 'Role',
            to: '/role',
            id: this.elementId('mainNavigation', 'role'),
            icon: { fa: 'mdi mdi-account-check' },
            order: 2,
            exact: false,
            show: true
          }
        ]
      }
    ];
    areas = this.processMenuAreas(areas);
    return areas;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private processMenuAreas(areas: MenuArea[]) {
    return areas
      .reduce(
        (processed: MenuArea[], area: MenuArea) => {
          if (area.section!.show) {
            area.items = this.processMenuItems(area.items);
            area.section!.text = this.$t(area.section!.text).toString();
            processed.push(area);
          }
          return processed;
        },
        [])
      .sort(numericSorter<MenuArea>((area: MenuArea) => area.section!.order));
  }
  private processMenuItems(items: MenuItem[]) {
    return items
      .reduce(
        (processed: MenuItem[], item: MenuItem) => {
          if (item.show) {
            item.text = this.$t(item.text).toString();
            processed.push(item);
          }
          return processed;
        },
        [])
      .sort(numericSorter<MenuItem>((item) => item.order));
  }
}
