











































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
// js functions and enums
import { cloneDeep, orderBy } from 'lodash';
import { SortType } from '@/enums/sort-type';
import { SortOptions } from '@/enums/sort-options';
// other components import
import Dropdown from '@/components/common/Dropdown.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import ObjectSearch from '@/components/common/ObjectSearch.vue';
import AddUserModal from '@/components/user/AddUserModal.vue';
// user store and data
import UserStore from '@/store-modules/userStore/userStore';
import { ICompany, IRole, IUser } from '@/view-models/user-model';
import EventBus from '@/shared/event-bus';
import SharedMethods from '@/shared/shared-methods';
import { isStringEmpty } from '@/utils/string';

@Component({
  name: 'user-list',
  components: { Dropdown, ObjectSearch, AddUserModal },
})
export default class UserList extends BaseComponent {
  public userStore = UserStore;
  public users: IUser[] = [];
  public unfilteredUsers: IUser[] = cloneDeep(this.userStore.getUsers);
  public filteredUsers: IUser[] = [];
  public selectedUser: IUser | null = null;
  public internalCompanies: ICompany[] = this.userStore.internalCompanies;
  public customerCompanies: ICompany[] = this.userStore.customerCompanies;
  public systemRoles: IRole[] = this.userStore.getSystemRoles;
  public customRoles: IRole[] = this.userStore.getCustomRoles;

  // search
  public searchResultUsers: string[] | null = null;
  public searchResultCompanies: ICompany[] | any = null;
  public searchResultRoles: IRole[] | any = null;

  // pagination variables
  private currentPage: number = 1;
  private perPage: number = 10;
  private paginatedUsers: IUser[] = []; // 2
  private totalRows: number = 0; // 3

  // sort and filter
  private sortType = SortType;
  private sortByOptions = Object.values(SortOptions);
  private selectedSort: string = SortType.MAIN; // 4
  private selectedSortBy: string = SortOptions.LASTMODIFIED; // 5
  private selectedCompanies: ICompany[] = []; // 6
  private selectedRoles: IRole[] = []; // 7
  private appliedFilters: number = 0;

  // shared class
  private sharedMethods: SharedMethods = new SharedMethods(this.userStore);

  private urlOrgKey: string = '';

  private searchManuallyCleared: boolean = false;

  public mounted() {
    this.showUserList();
  }

  public showUserList() {
    if (this.$route.query.orgKey) {
      this.urlOrgKey = this.$route.query.orgKey.toString();
    }
    const redirectedOrg = this.sharedMethods.getOrg(this.urlOrgKey);
    if (redirectedOrg) {
      redirectedOrg.isSelected = true;
      this.userStore.updateSelectedCompanies(redirectedOrg);
      this.selectedCompanies = [redirectedOrg];
      this.sortByCompaniesRoles();
    } else {
      this.clearFilters(true);
    }
  }

  public roleOrgName(role: IRole): string | undefined {
    return this.sharedMethods.getOrgName(role);
  }

  // select user
  public selectUser(user: IUser): void {
    const userKey: string = user?.key ?? '';
    this.userStore.setIsUserLoading(true);
    if (!isStringEmpty(user?.key)) {
      this.userStore.getSelectedUserByKey(userKey);
    } else {
      this.userStore.setSelectedUser(null);
    }
    this.userStore.setIsUserLoading(false);
  }

  public userOrgName(user: IUser): string | undefined {
    return this.sharedMethods.getOrgName(user);
  }

 public orderSearchResults() {
    const usersResult: IUser[] = [];
    if (this.searchResultUsers) {
      this.unfilteredUsers.forEach((user) => {
        for (let i=0; i < this.searchResultUsers!.length; i++) {
          if (user.email === this.searchResultUsers![i]) {
            usersResult[i] = user;
          }
        }
      });
      this.users = usersResult;
    }
  }
  // search users
  get resultUsers(): string[] | null {
    return this.searchResultUsers;
  }

  set resultUsers(value: string[] | null) {
    if (this.appliedFilters > 0) {
      if (!this.searchManuallyCleared) {
        if (value == null) {
          value = [];
          for (const user in this.filteredUsers) {
            if (user) {
              value[user] = this.filteredUsers[user].email;
            }
          }
          this.searchResultUsers = value;
        } else {
          this.searchResultUsers = value;
        }
      }
    } else {
      this.searchResultUsers = value;
      this.clearFilters(true);
    }
    this.orderSearchResults();
    this.setPagination();
  }

  get resultCompanies(): ICompany[] {
    return this.searchResultCompanies;
  }

  set resultCompanies(value: ICompany[]) {
    this.searchResultCompanies = value;
    this.internalCompanies = this.userStore.internalCompanies;
    this.customerCompanies = this.userStore.customerCompanies;
    if (value) {
      this.internalCompanies = this.internalCompanies.filter(
        (company: ICompany) => this.searchResultCompanies.indexOf(company.key) !== -1
      );
      this.customerCompanies = this.customerCompanies.filter(
        (company: ICompany) => this.searchResultCompanies.indexOf(company.key) !== -1
      );
    }
  }

  get resultRoles(): IRole[] {
    return this.searchResultRoles;
  }

  set resultRoles(value: IRole[]) {
    this.searchResultRoles = value;
    this.systemRoles = this.userStore.systemRoles;
    this.customRoles = this.userStore.customRoles;
    if (value) {
      this.systemRoles = this.systemRoles.filter((role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1);
      this.customRoles = this.customRoles.filter((role: IRole) => this.searchResultRoles.indexOf(role.key) !== -1);
    }
  }

  // add user
  public showAddModal(): void {
    this.userStore.setCloseEdit(true);
    EventBus.$emit('addUserModal::show');
  }

  // get number of filters applied
  public setFiltersCount(): void {
    this.appliedFilters = 0;
    // company filter
    if (this.selectedCompanies.length > 0) {
      this.appliedFilters = this.appliedFilters + 1;
    }
    // roles filter
    if (this.selectedRoles.length > 0) {
      this.appliedFilters = this.appliedFilters + 1;
    }
  }

  // clear filters and reset values
  public clearFilters(firstTimeLanding: boolean = false, goToFirstUser: boolean = true): void {
    this.users = this.unfilteredUsers;
    this.currentPage = 1;
    this.selectedCompanies.forEach((c: ICompany) => (c.isSelected = false));
    this.selectedRoles.forEach((c: IRole) => (c.isSelected = false));
    this.selectedCompanies.length = 0;
    this.selectedRoles = [];
    this.sortByChange(SortOptions.LASTMODIFIED, firstTimeLanding, false, goToFirstUser);
    this.setFiltersCount();
  }

  // set pagination and sort
  public setPagination(selectFirstUser: boolean = true, goToMainPage: boolean = true): void {
    if (selectFirstUser && this.users.length > 0) {
      this.selectUser(this.users[0]);
    }
    this.paginatedUsers = this.users;
    this.totalRows = this.users.length;
    this.paginate(this.perPage, 0);
    if (goToMainPage) {
      this.goToSortOption(this.sortType.MAIN);
    }
    this.setFiltersCount();
    this.currentPage = 1;
  }

  /* Sort Users */
  public sortByChange(
    option: string,
    firstTimeLanding: boolean,
    filterFirst?: boolean,
    goToFirstUser: boolean = true,
    setSortPageMain: boolean = true,
    clearSearch: boolean = false
  ): void {
    if (clearSearch) {
      this.searchManuallyCleared = true;
      this.clearSearchInput();
    }
    if (filterFirst) {
      this.filterUsersCompanyRoles();
    }
    this.selectedSortBy = option;
    switch (option) {
      case SortOptions.FIRSTNAME:
        this.users = orderBy(this.users, [(user: IUser) => user.firstName.trim().toLowerCase()], ['asc']);
        break;
      case SortOptions.LASTNAME:
        const usersWithNoLastName: IUser[] = this.users.filter((user: IUser) => user.lastName === '');
        let usersWithLastName: IUser[] = this.users.filter((user: IUser) => user.lastName !== '');
        usersWithLastName = orderBy(usersWithLastName, [(user: IUser) => user.lastName.trim().toLowerCase()], ['asc']);
        this.users = [...usersWithLastName, ...usersWithNoLastName];
        break;
      default:
        this.users = orderBy(this.users, ['lastModifiedAt'], ['desc']);
    }
    this.setPagination(!goToFirstUser, setSortPageMain);
    if (!firstTimeLanding) {
      this.toggleSortFilterDropDown();
    }
    setTimeout(() => (this.searchManuallyCleared = false), 1000);
  }

  public filterUsersCompanyRoles(clearSearch: boolean = false): void {
    if (clearSearch) {
      this.searchManuallyCleared = true;
      this.clearSearchInput();
    }
    this.users = this.unfilteredUsers;
    // sort by companies
    if (this.selectedCompanies.length > 0) {
      this.users = this.users.filter((user: IUser) =>
        this.selectedCompanies.some((company: ICompany) => user.orgKey === company.key)
      );
      this.filteredUsers = this.users;
    } else {
      this.filteredUsers = [];
    }
    // sort by roles
    if (this.selectedRoles.length > 0) {
      this.users = this.users.filter((user: IUser) =>
        this.selectedRoles.some((role: IRole) => user.roleKeys.includes(role.key))
      );
      this.filteredUsers = this.users;
    }
    setTimeout(() => (this.searchManuallyCleared = false), 1000);
  }

  public filterUsersUsingCompany(option: ICompany): void {
    option.isSelected = !option.isSelected;
    this.userStore.updateSelectedCompanies(option);
    this.selectedCompanies = this.userStore.allCompanies.filter((company) => company.isSelected);
    this.filterUsersCompanyRoles(true);
    this.sortByChange(this.selectedSortBy, true, false, true, false);
  }

  public filterUsersUsingRole(option: IRole): void {
    option.isSelected = !option.isSelected;
    this.userStore.updateSelectedRoles(option);
    this.selectedRoles = this.userStore.allRoles.filter((company) => company.isSelected);
    this.filterUsersCompanyRoles(true);
    this.sortByChange(this.selectedSortBy, true, false, true, false);
  }

  public sortByCompaniesRoles(): void {
    setTimeout(() => {
      this.filterUsersCompanyRoles();
      this.sortByChange(this.selectedSortBy, true, false, true, false);
    }, 1);
  }

  public goToSortOption(option: string): void {
    this.selectedSort = option;
  }

  // close dropdown
  public toggleSortFilterDropDown(): void {
    if (this.$refs && this.$refs.sortFilterDropdown) {
      (this.$refs.sortFilterDropdown as Vue & { toggleMenu: () => void }).toggleMenu();
    }
  }

  // clear search
  public clearSearchInput(): void {
    if (this.$refs && this.$refs.searchUsersInput) {
      (this.$refs.searchUsersInput as Vue & { clearSearchText: () => void }).clearSearchText();
    }
  }

  /* pagination methods below */
  public paginate(pageSize: number, pageNumber: number): void {
    const itemsToParse = this.users;
    this.paginatedUsers = itemsToParse.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  public onPageChanged(page: number, updateStore: boolean = true): void {
    this.currentPage = page;
    this.paginate(this.perPage, page - 1);
    if (updateStore) {
      this.userStore.setGoToUserPage(page);
    }
  }

  @Watch('userStore.allUsers', { immediate: true })
  private onUsersChange(unused1: IUser[], unused: IUser[]) {
    this.showUserList();
  }

  @Watch('userStore.clearFilters', { immediate: true })
  private onClearFilter(unused1: boolean, unused2: boolean) {
    this.clearFilters(true, false);
  }

  @Watch('userStore.goToPage', { immediate: true })
  private onPageChange(newNumber: number, unused: number) {
    this.onPageChanged(newNumber, false);
  }

  // Event methods...
  private onNewUserModalClosed() {
    this.setPagination(true, false);
  }
}
