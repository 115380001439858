import Vue from 'vue';
import Vuex from 'vuex';
import { IAppStore } from '@/store-modules/types/app';
import { IErrorStore } from '@/store-modules/types/error';
import { IRoleStore } from '@/store-modules/types/role';
import { IUserStore } from '@/store-modules/types/user';
import { IUserAssignmentsStore } from './types/userAssignments';

Vue.use(Vuex);

interface IState {
  app: IAppStore;
  error: IErrorStore;
  user: IUserStore;
  userAssignments: IUserAssignmentsStore;
  role: IRoleStore;
}
export default new Vuex.Store<IState>({});

