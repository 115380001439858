








































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import UserAssignmentsStore from '@/store-modules/userStore/userAssignmentsStore';
import { AssetListItemTypeEnum, CheckedState, IAssignmentTreeItemViewModel } from '@/view-models/assignment-model';

@Component({
  name: 'user-assignments-tree-view',
  components: {},
})
export default class UserAssignmentsTreeView extends BaseComponent {
  @Prop({ required: true })
  public item!: IAssignmentTreeItemViewModel;
  @Prop({ required: true })
  public assets!: string[];
  // Is Tree view used for assign/unassign purpose
  @Prop({ default: false })
  public isAssignableTree!: boolean;
  @Prop({ default: false })
  public isViewOnly!: boolean;

  public AssetListItemTypeEnum = AssetListItemTypeEnum;
  public CheckedState = CheckedState;

  private get isAssigned() {
    return this.item.isAssigned ?? false;
  }

  private get isDisabled(): boolean {
    if (this.isViewOnly) {
      return false;
    }

    if (!this.item.isAssignable) {
      return true;
    }

    return this.isAssignableTree ? this.isAssigned : !this.isAssigned;
  }

  private get isVisible(): boolean {
    if (this.item) {
      if (this.isAssignableTree) {
        return !this.item.isAssigned ?? false;
      } else {
        return this.item.isVisible ?? false;
      }
    }
    return false;
  }

  get childrenItemsCount(): number {
    return this.isAssignableTree
      ? this.childrenItems.filter((child) => !child.isAssigned).length ?? 0
      : this.childrenItems.filter((child) => child.isVisible).length ?? 0;
  }

  private get childrenItems(): IAssignmentTreeItemViewModel[] {
    return UserAssignmentsStore.childrenAssignments(this.item.key);
  }

  get isOpen(): boolean {
    return this.item.isOpen || false;
  }

  set isOpen(value: boolean) {
    const newItem: IAssignmentTreeItemViewModel = Object.assign({}, this.item);
    newItem.isOpen = value;
    UserAssignmentsStore.updateAssignableAssignments(newItem);
  }

  public handleCollapseAllOnAssetItemClick() {
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      this.assets.push(this.item.key);
    } else {
      this.assets.splice(this.assets.indexOf(this.item.key), 1);
    }
  }

  public async onClickItem(): Promise<void> {
    UserAssignmentsStore.toggleAssignedAssignment(this.item);
  }
}
