
































// Libraries
import { Component, Prop } from 'vue-property-decorator';
import { MenuArea, MenuItem } from '@/view-models/nav-section';
// Components
import BaseComponent from '@/shared/BaseComponent.vue';

@Component({
  name: 'nav-section'
})
export default class NavSection extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public area!: MenuArea;

  @Prop({ required: true })
  public index!: number;
  // Getters
  public get hasLogo(): boolean {
    return this.area.image !== null || this.area.image !== '';
  }
  public get isLogoCol(): boolean {
    return this.hasLogo && this.index === 0;
  }

  public getRoutePath(item: MenuItem) {
    if (item.name === 'assets-key') {
      return { name: item.name };
    } else {
      return { path: item.to };
    }
  }

  public handleMainClick(event: Event, item: MenuItem): void {
    if (item.click != null && typeof item.click === 'function') {
      item.click(event);
    }
  }
}
