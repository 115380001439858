import moment from 'moment';

function nullComparer<T>(a: T, b: T) {
  if (b == null && a == null) {
    return 0;
  }
  if (a == null && b != null) {
    return -1;
  }
  if (a != null && b == null) {
    return 1;
  }

  return null; // neither are null, so cannot compare
}

function alphabeticSorter(): (a: string, b: string) => number;
function alphabeticSorter<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorter<T>(selector?: (o: T) => string) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = nullComparer(sA, sB);
      return res != null ? res : sA.localeCompare(sB, undefined, { sensitivity: 'base' });
    };
  }

  return (a: string, b: string) => {
    const res = nullComparer(a, b);
    return res != null ? res : a.localeCompare(b, undefined, { sensitivity: 'base' });
  };
}

function numericSorter(): (a: number, b: number) => number;
function numericSorter<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorter<T>(selector?: (o: T) => number) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = nullComparer(sA, sB);
      return res != null ? res : sA - sB;
    };
  }
  return (a: number, b: number) => {
    const res = nullComparer(a, b);
    return res != null ? res : a - b;
  };
}

function dateSorter(): (a: Date | string, b: Date | string) => number;
function dateSorter<T>(selector: (o: T) => Date | string): (a: T, b: T) => number;
function dateSorter<T>(selector?: (o: T) => Date | string) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = nullComparer(sA, sB);
      if (res != null) {
        return res;
      }

      const anchor = moment(sA);
      return anchor.isSame(sB) ? 0 : (anchor.isAfter(sB) ? -1 : 1);
    };
  }
  return (a: Date | string, b: Date | string) => {
    const res = nullComparer(a, b);
    if (res != null) {
      return res;
    }
    const anchor = moment(a);
    return anchor.isSame(b) ? 0 : (anchor.isAfter(b) ? -1 : 1);
  };
}

export { alphabeticSorter, numericSorter, dateSorter };
