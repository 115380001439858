import {
  Module,
  VuexModule,
  getModule,
  Mutation
} from 'vuex-module-decorators';
import store from '@/store-modules';
import { IErrorStore } from '@/store-modules/types/error';

import { ErrorBusMessage, IErrorBusMessage, IErrorMessageConfig } from '@/view-models/error-model';
import {parentEventBus} from '@/utils/from-parent';

const EVENT_NAME = 'GLOBAL_ERROR';
const APP_TAG = 'User-Management';

@Module({
  namespaced: true,
  name: 'errorStore',
  store,
  dynamic: true,
})


class ErrorStore extends VuexModule implements IErrorStore {
  // State
  public error: any = null;
  public showModal: boolean = false;

  // Getters
  public get getError() {
    return this.error;
  }

  // Mutations
  @Mutation
  public setError(payload: {error: any, config?: IErrorMessageConfig, uiError?: boolean}): void {
    this.error = payload.error;
    emitError(payload.error, payload.config, payload.uiError);
  }
  @Mutation
  public clearError(): void {
    this.error = null;
    this.showModal = false;
  }
  // Actions
}

export default getModule(ErrorStore);

const emitError = (error: any, config?: IErrorMessageConfig, uiError?: boolean): void => {
  const eventBus = parentEventBus();
  if (eventBus != null && !uiError) {
    const emitEvent: IErrorBusMessage = new ErrorBusMessage(APP_TAG, error, config);
    eventBus.$emit(EVENT_NAME, emitEvent);
  } else {
    ErrorStore.state.showModal = true;
  }
};
