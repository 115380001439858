import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store-modules';
import { UsersService } from '@/services/user-service';
import { IUser, ICompany, IRole, IUserAssignment } from '@/view-models/user-model';
import { IUserStore } from '@/store-modules/types/user';
import { forEach } from 'lodash';
import { AdvancedPermissions, BasicPermissions } from '@/enums/permissions';
import { userStatusMapper } from '@/enums/user-status';
import { RolesService } from '@/services/role-service';
import Vue from 'vue';

@Module({
  namespaced: true,
  name: 'userStore',
  store,
  dynamic: true,
})
class UserStore extends VuexModule implements IUserStore {
  // State
  public header: string = '';
  public currentUser: IUser | null = null;
  public selectedUser: IUser | null = null;
  public allUsers: IUser[] = [];

  public allRoles: IRole[] = [];
  public systemRoles: IRole[] = [];
  public customRoles: IRole[] = [];

  public allCompanies: ICompany[] = [];
  public internalCompanies: ICompany[] = [];
  public customerCompanies: ICompany[] = [];

  public showLoader: boolean = false;
  public clearFilters: boolean = true;
  public goToPage: number = 1;

  public userEmailDuplicationError: string | undefined = '';
  public closeEditUser: boolean = false;
  public isUserLoading: boolean = false;

  // Getters
  public get getCloseEditUser(): boolean {
    return this.closeEditUser;
  }

  public get isAdminCompanyUser() {
    return this.currentUser?.isAdminCompanyUser;
  }

  public get isSsoUserSelected() {
    return this.selectedUser?.isSsoUser;
  }

  public get hasCreateUserAndRolePermission() {
    return this.currentUser?.permissions?.includes(BasicPermissions.CreateUserAndRole) ?? false;
  }

  public get hasManageUserAndRolePermission() {
    return this.currentUser?.permissions?.includes(BasicPermissions.ManageUserAndRole) ?? false;
  }

  public get hasDeleteUserAndRolePermission() {
    return this.currentUser?.permissions?.includes(AdvancedPermissions.DeleteUserAndRole) ?? false;
  }

  public get hasSystemRoleCreateUserAndRolePermission(): boolean {
    return this.currentUser?.systemRolePermissions?.includes(BasicPermissions.CreateUserAndRole) ?? false;
  }

  public get hasSystemRoleCreateUserAndDeleteUserPermission(): boolean {
    const permissions = this.currentUser?.systemRolePermissions;
    const hasThem =
      permissions?.includes(BasicPermissions.ManageUserAndRole) &&
      permissions?.includes(AdvancedPermissions.DeleteUserAndRole);
    return hasThem ?? false;
  }

  public get getSelectedUser(): IUser | null {
    return this.selectedUser;
  }

  public get getUsers(): IUser[] {
    return this.allUsers;
  }

  public get getRoles(): IRole[] {
    return this.allRoles;
  }

  public get getCompanies(): ICompany[] {
    return this.allCompanies;
  }

  public get getSystemRoles(): IRole[] {
    return this.systemRoles;
  }

  public get getCustomRoles(): IRole[] {
    return this.customRoles;
  }

  public get nonAdminInternalCompanies() {
    return this.internalCompanies.filter((ci: ICompany) => !ci.isAdminCompany);
  }

  public get getInternalCompaniesByPermission(): ICompany[] {
    if (this.hasSystemRoleCreateUserAndRolePermission) {
      return this.internalCompanies;
    } else {
      return this.internalCompanies.filter((company: ICompany) =>
        company.currentUserPermissions?.includes(BasicPermissions.CreateUserAndRole)
      );
    }
  }

  public get getCustomerCompaniesByPermission(): ICompany[] {
    if (this.hasSystemRoleCreateUserAndRolePermission) {
      return this.customerCompanies;
    } else {
      return this.customerCompanies.filter((company: ICompany) =>
        company.currentUserPermissions?.includes(BasicPermissions.CreateUserAndRole)
      );
    }
  }

  public get getInternalCompanies(): ICompany[] {
    return this.internalCompanies;
  }

  public get getCustomerCompanies(): ICompany[] {
    return this.customerCompanies;
  }

  public get getShowLoader(): boolean {
    return this.showLoader;
  }

  public get getClearFilter(): boolean {
    return this.clearFilters;
  }

  public get getPageNumber(): number {
    return this.goToPage;
  }

  public get getOrganizationName(): string | undefined {
    let matchingOrgName: string | undefined;
    if (this.selectedUser) {
      matchingOrgName = this.allCompanies.find((company: ICompany) => company.key === this.selectedUser?.orgKey)?.name;
    }
    return matchingOrgName;
  }

  public get getUserRoleNames(): string {
    const roleNames: string[] = [];
    if (this.selectedUser) {
      forEach(this.selectedUser.roleKeys, (roleKey) => {
        const roleName: string | undefined = this.allRoles.find((allRole: IRole) => allRole.key === roleKey)?.name;
        if (roleName) {
          roleNames.push(roleName);
        }
      });
    }
    return roleNames.join(', ');
  }

  public get getUserByKey(): string | undefined {
    let matchingUserName: string | undefined;
    if (this.selectedUser) {
      matchingUserName = this.allUsers.find((user: IUser) => user.key === this.selectedUser?.lastModifiedBy)?.name;
    }
    return matchingUserName;
  }

  public get getUserEmailError(): string | undefined {
    return this.userEmailDuplicationError;
  }

  // Mutations
  @Mutation
  public setCloseEdit(value: boolean): void {
    this.closeEditUser = value;
  }

  @Mutation
  public setHeader(header: string): void {
    this.header = header;
  }

  @Mutation
  public setSelectedUser(selectedUser: IUser | null): void {
    this.selectedUser = selectedUser;
  }

  @Mutation
  public setSelectedUserAssignments(userAssignment: IUserAssignment[]): void {
    if (this.selectedUser) {
      this.selectedUser.userAssignments = userAssignment;
    }
  }

  @Mutation
  public updateUserData(userToUpdate: IUser) {
    const userIndex = this.allUsers.findIndex((user) => user.key === userToUpdate?.key);
    Vue.set(this.allUsers, userIndex, userToUpdate);
  }

  @Mutation
  public setAllUsers(allUsers: IUser[]): void {
    this.allUsers = [];
    this.allUsers = allUsers;
    if (this.allCompanies) {
      this.allUsers.forEach((user: IUser) => {
        const orgName = this.allCompanies.find((company: ICompany) => company.key === user.orgKey)?.name;
        user.orgName = orgName;
      });
    }
  }

  @Mutation
  public insertNewUser(newUser: IUser): void {
    if (newUser == null) {
      return;
    }
    if (this.allCompanies) {
      newUser.orgName = this.allCompanies.find((company: ICompany) => company.key === newUser.orgKey)?.name;
    }
    this.allUsers = [newUser, ...this.allUsers];
  }

  @Mutation
  public setAllRoles(allRoles: IRole[]): void {
    this.allRoles = allRoles;
    this.allRoles.forEach((c: IRole) => (c.isSelected = false));
    if (this.allCompanies) {
      this.allRoles.forEach((role: IRole) => {
        const orgName = this.allCompanies.find((company: ICompany) => company.key === role.orgKey)?.name;
        role.orgName = orgName;
      });
    }
  }

  @Mutation
  public setSystemCustomRoles(allRoles: IRole[]): void {
    if (allRoles && allRoles.length > 0) {
      this.systemRoles = [];
      this.customRoles = [];
      this.systemRoles = this.allRoles.filter((role: IRole) => role.isSystemRole);
      this.customRoles = this.allRoles.filter((role: IRole) => !role.isSystemRole);
    }
  }

  @Mutation
  public setAllCompanies(allCompanies: ICompany[]): void {
    this.allCompanies = allCompanies;
    this.allCompanies.forEach((c: ICompany) => (c.isSelected = undefined));
  }

  @Mutation
  public setInternalCustomerCompanies(allCompanies: ICompany[]): void {
    if (allCompanies && allCompanies.length > 0) {
      this.internalCompanies = [];
      this.customerCompanies = [];
      this.internalCompanies = this.allCompanies.filter((company: ICompany) => !company.isCustomer);
      this.customerCompanies = this.allCompanies.filter((company: ICompany) => company.isCustomer);
    }
  }

  @Mutation
  public setUserLoaded(value: boolean): void {
    this.showLoader = value;
  }
  @Mutation
  public setClearFilter(value: boolean): void {
    this.clearFilters = value;
  }

  @Mutation
  public setGoToUserPage(page: number): void {
    this.goToPage = Math.ceil(page);
  }

  @Mutation
  public setUserError(value: string | undefined): void {
    this.userEmailDuplicationError = value;
  }

  @Mutation
  public setCurrentUser(user: IUser): void {
    this.currentUser = user;
  }

  @Mutation
  public updateSelectedCompanies(company: ICompany): void {
    const index = this.allCompanies.findIndex((item) => item.key === company.key);
    this.allCompanies.splice(index, 1, company);
  }

  @Mutation
  public updateSelectedRoles(role: IRole): void {
    if (role.isSystemRole) {
      const index = this.systemRoles.findIndex((item) => item.key === role.key);
      this.systemRoles.splice(index, 1, role);
    } else {
      const index = this.customRoles.findIndex((item) => item.key === role.key);
      this.customRoles.splice(index, 1, role);
    }
  }

  @Mutation
  public setIsUserLoading(value: boolean): void {
    this.isUserLoading = value;
  }

  // Actions
  @Action({ rawError: true })
  public async getAllUsers(): Promise<void> {
    const usersService = await UsersService.factory();
    await usersService.getAllUsers().then((response) => {
      this.setAllUsers(response);
      this.setSelectedUser(response[0]);
    });
  }

  @Action({ rawError: true })
  public async getAllRoles(): Promise<void> {
    const usersService = await RolesService.factory();
    const response = await usersService.getAllRoles();
    this.setAllRoles(response);
    this.setSystemCustomRoles(response);
  }

  @Action({ rawError: true })
  public async getAllCompanies(): Promise<void> {
    const usersService = await UsersService.factory();
    await usersService.getAllCompanies().then((response) => {
      this.setAllCompanies(response);
      this.setInternalCustomerCompanies(response);
    });
  }

  @Action({ rawError: true })
  public async updateUser(user: IUser | null): Promise<void> {
    const usersService = await UsersService.factory();
    this.setIsUserLoading(true);
    if (user) {
      await usersService.updateUser(user).then((response) => {
        this.setSelectedUser(response);
        this.updateUserData(response);
      });
    }
    this.setIsUserLoading(false);
  }

  @Action({ rawError: true })
  public async addUser(user: IUser): Promise<void> {
    if (user == null) {
      return;
    }
    const usersService = await UsersService.factory();
    try {
      const createdUser = await usersService.addUser(user);
      if (createdUser != null) {
        this.insertNewUser(createdUser);
        this.setSelectedUser(createdUser);
      }
    } catch (error) {
      const myError = (error as any).response.data.toString();
      if (myError.includes('email')) {
        this.setUserError(myError);
      } else {
        throw error;
      }
    }
  }

  @Action({ rawError: true })
  public async activateDeactivateUser(user: IUser): Promise<void> {
    const status = userStatusMapper.get(user.status);
    const usersService = await UsersService.factory();
    if (user) {
      await usersService.activateDeactivateUser(user, status).then(() => {
        // get users
      });
      this.getAllUsers();
    }
  }

  @Action({ rawError: true })
  public async getUserCurrent(): Promise<void> {
    const usersService = await UsersService.factory();
    const user = await usersService.getUserCurrent();
    this.setCurrentUser(user);
  }

  @Action({ rawError: true })
  public async getSelectedUserByKey(userKey: string): Promise<void> {
    const usersService = await UsersService.factory();
    if (userKey) {
      await usersService.getUserByKey(userKey).then((response) => {
        this.setSelectedUser(response);
        this.setIsUserLoading(false);
      });
    }
  }

  @Action({ rawError: true })
  public async resendEmail(user: IUser): Promise<void> {
    const usersService = await UsersService.factory();
    await usersService.resendEmail(user).then(() => {
      // reload the page after resend mail
      this.getAllUsers();
    });
  }
}

export default getModule(UserStore);
